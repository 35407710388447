/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import { useFormik } from "formik";
export default function ContactList({
  contacts,
  onContactCreated,
  onContactedUpdated,
  onContactedDeleted,
}) {
  const defaultContact = {
    name: "",
    phone: "",
    mobile: "",
    email: "",
    active: true,
  };
  const [open, setOpen] = useState(false);
  const [contactList, setContactList] = useState(contacts);
  const [contact, setContact] = useState(defaultContact);
  const formik = useFormik({
    initialValues: contact,
    enableReinitialize: true,
    onSubmit: (updatedContact, { resetForm }) => {
      setOpen(false);
      if (updatedContact.id) {
        setContactList((cs) =>
          cs.map((ec, index) =>
            index == updatedContact.index ? updatedContact : ec
          )
        );
        setContact(defaultContact);
        onContactedUpdated(updatedContact);
      } else {
        updatedContact.id = parseInt(Date.now() / 100000000);
        setContactList((cs) => [...cs, updatedContact]);
        setContact(defaultContact);
        onContactCreated(updatedContact);
      }
      resetForm({ values: "" });
    },
  });

  const deleteContact = (contact, _) => {
    let index = contactList.indexOf(contact);
    contactList.splice(index, 1);
    setContactList([...contactList]);
    onContactedDeleted(contact);
  };

  const editContact = (c, index, _) => {
    setContact({ ...c, index: index });
    setOpen(true);
  };
  const newContact = () => {
    setContact(defaultContact);
    formik.initialValues = defaultContact;
    setOpen(true);
  };

  return (
    <div className="h-[70vh] overflow-y-scroll pr-4">
      <ul role="list" className="divide-y divide-gray-200 ">
        {contactList?.map((contact, index) => (
          <li key={index} className="py-4 flex justify-between">
            <div className="flex">
              <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-blue-500">
                <span className="text-lg  leading-none text-white">
                  {contact?.name?.charAt(0)}
                </span>
              </span>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {contact?.name}
                </p>
                <p className="text-sm text-gray-500">{contact?.email}</p>
                <p className="text-sm text-gray-500">{contact?.phone}</p>
                <p className="text-sm text-gray-500">{contact?.mobile}</p>
              </div>
            </div>
            <div className="flex gap-10">
              <button
                type="button"
                className="ml-auto h-10 inline-flex items-center w-16 justify-center border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={editContact.bind(this, contact, index)}
              >
                Edit
              </button>
              <button
                type="button"
                className="ml-auto h-10 inline-flex items-center w-16 justify-center border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={deleteContact.bind(this, contact)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex">
        <button
          type="button"
          className="ml-auto text-right h-10 inline-flex items-center w-16 justify-center border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() => newContact()}
        >
          +New
        </button>
      </div>
      {open && (
        <form className="shadow-xl" onSubmit={formik.handleSubmit}>
          <div className="flex-1">
            <label
              htmlFor="name"
              className="flex ml-2 text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              {contact.id ? "Update" : "New"}&nbsp;Contact
            </label>
            <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="name"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Name
                    <span className="block text-red-500 mt-0.5 pl-1">*</span>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </div>
              </div>
            </div>
            <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="email"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Email
                    <span className="block text-red-500 mt-0.5 pl-1">*</span>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
              </div>
            </div>
            <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="phone"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Phone
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                </div>
              </div>
            </div>
            {/* <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="mobile"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Mobile
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    name="mobile"
                    id="mobile"
                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                  />
                </div>
              </div>
            </div> */}
            <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="active"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Active
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    id="active"
                    name="active"
                    type="checkbox"
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    defaultChecked={formik.values.active}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6">
            <div className="space-x-3 flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {contact.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
