import {
  ChevronDownIcon,
  ChevronRightIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import ReactModal from "react-modal";
import { formatDate } from "@telerik/kendo-intl";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import EditableKendoTable from "./EditableKendoTable";

const LocationDetails = ({
  index,
  expandToggle,
  location,
  expandedLocations,
  quotation,
  serviceTemplate,
  setQuotation,
  logisticServices,
  mode,
  selectedOption,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingSelection, setPendingSelection] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOk = (serviceId) => {
    setIsModalOpen(false);
    setSelectedItem(pendingSelection);
    saveTemplateServices(pendingSelection, serviceId);
    setPendingSelection(null);
  };

  const save = async (updatedData, locationId) => {
    try {
      UpdateLocationData("details", updatedData);
    } catch (errInfo) {
      console.error("Validate Failed:", errInfo);
    }
  };

  const onServiceExpectedChange = (loc, date) => {
    const selectedDate = new Date(date); // Parse the date string into a JavaScript Date
    selectedDate.setHours(12, 0, 0); // Adjust the time to noon if needed
    UpdateLocationData("expectedDate", selectedDate);

    setOpenDatePicker(false);
  };

  const handleComment = (comment) => {
    UpdateLocationData("comment", comment);
  };

  const UpdateLocationData = (field, value) => {
    setQuotation((prevQuotation) => ({
      ...prevQuotation,
      shipment: prevQuotation.shipment.map((shipment) =>
        shipment.mode === mode
          ? {
              ...shipment,
              options: shipment.options.map((option) =>
                option.id === selectedOption.id
                  ? {
                      ...option,
                      services: option.services.map((loc) =>
                        loc.id === location.id
                          ? { ...loc, [field]: value }
                          : loc
                      ),
                    }
                  : option
              ),
            }
          : shipment
      ),
    }));
  };

  const saveTemplateServices = async (data, serviceId) => {
    try {
      const services = data.logisticsServices || [];
      const serviceData = services.map((service) => ({
        index: service.id.toString(),
        serviceId: service.id,
        service: service.name || "",
        description: service.description || "",
        price: service.price || 0,
        quantity: service.quantity || 1,
        amount: (service.price || 0) * (service.quantity || 1),
      }));
      UpdateLocationData("details", serviceData);
    } catch (errInfo) {
      console.error("Error saving template services:", errInfo);
    }
  };

  const handleServiceChange = (event) => {
    setPendingSelection(event.target.value);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPendingSelection(null);
  };

  return (
    <div key={index} className="flex gap-2">
      <div className="flex flex-col justify-center items-center gap-2">
        <LocationMarkerIcon className="ml-auto flex-shrink-0 h-5 w-5 text-blue-500" />

        <div className="h-full border-l-2 border-dashed" />
      </div>
      <div key={index} className="mb-6 w-full">
        <div className="flex justify-between gap-3 mb-2">
          <div className="flex items-center gap-3 mb-2">
            <div onClick={() => expandToggle(location.id)}>
              {expandedLocations.includes(location.id) ? (
                <ChevronDownIcon className="ml-auto flex-shrink-0 h-5 w-5 text-blue-500" />
              ) : (
                <ChevronRightIcon className="ml-auto flex-shrink-0 h-5 w-5 text-blue-500" />
              )}
            </div>
            <div>{quotation.source}</div>
            <div
              className="flex items-center border border-gray-400 rounded text-gray-600 gap-2 w-fit px-2"
              onDoubleClick={() => setOpenDatePicker(true)}
            >
              <div>ETD</div>
              <div>
                {!openDatePicker ? (
                  formatDate(new Date(location.expectedDate), "dd-MM-yyyy")
                ) : (
                  <div
                    className="my-1 flex w-40 h-6 border rounded-md border-gray-300"
                    id="poDateCompo"
                  >
                    <DatePicker
                      name="expectedDate"
                      className="h-full w-full py-2 px-1 shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="Date"
                      format="DD-MM-YYYY" // Specify your desired format
                      value={
                        location.expectedDate
                          ? dayjs(location.expectedDate)
                          : null
                      }
                      required={false}
                      onChange={(e) => onServiceExpectedChange(location, e)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {expandedLocations.includes(location.id) && (
            <div
              className="mt-1 mr-2 bg-white flex items-center gap-3 "
              id="poCompo"
            >
              <ComboBox
                data={serviceTemplate}
                name="service"
                textField="name"
                className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                dataItemKey="code"
                suggest={true}
                clearButton={true}
                placeholder="Import Tariff"
                onChange={(event) => handleServiceChange(event)}
                value={pendingSelection}
              />
              <ReactModal
                isOpen={isModalOpen}
                //onAfterOpen={afterOpenModal}
                onRequestClose={() => setIsModalOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="OverlayBlack"
                ariaHideApp={false}
              >
                <div className="flex flex-col gap-y-5">
                  <p>
                    This will delete the existing services. Do you want to
                    continue?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={handleCancel}
                      className="flex justify-center items-center py-1 bg-black w-20 text-white border rounded-md"
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleOk(location.id)}
                      className="flex justify-center items-center py-1 bg-green-500 w-20 text-white rounded-md"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </ReactModal>
              {/* <button
                      onClick={() => addNewRow(location.id)}
                      className="p-2  border !text-white rounded-full text-sm"
                    >
                      <img
                        src="plus.svg"
                        className="w-6 cursor-pointer text-white "
                      />
                    </button> */}
            </div>
          )}
        </div>
        {expandedLocations.includes(location.id) && (
          <div key={location.id} className="expanded-content">
            <EditableKendoTable
              services={logisticServices}
              data={location?.details || []}
              locationId={location?.id}
              save={save}
            />

            <div className="flex flex-row items-center justify-between mt-4 pr-5">
              <div className="bg-white p-4 mt-1 w-1/2 rounded mb-4">
                <div className="font-semibold px-1 pb-2">Comments</div>
                <textarea
                  className="w-full focus:outline-none focus:ring-0 border-neutral-300 rounded"
                  placeholder="Some notes about the option"
                  rows={2}
                  onChange={(e) => handleComment(e.target.value, location)}
                  value={location?.comment || ""}
                ></textarea>
              </div>
              <div className="">
                <p className="text-xl font-semibold">
                  <span className="text-base p-2 text-neutral-600">
                    Total :
                  </span>
                  {location.details.reduce(
                    (a, b) => a + b.price * b.quantity,
                    0
                  ) || 0}
                  &nbsp; {quotation?.currencyCode}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationDetails;
