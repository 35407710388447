import React, { useState, useEffect, useContext } from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuSort,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import axios from "axios";
import { BASE_URL, movementStatuses } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatDate } from "@telerik/kendo-intl";
import PoActionsSlider from "./PoActionsSlider";
import AppContext from "../../context/app-context";
import { CustomColumnMenu } from "../CollumnMenu";
import KendoLoader from "../KendoLoader";
import { Tooltip } from "@progress/kendo-react-tooltip";
import colors from "tailwindcss/colors";
import { debounce } from "lodash";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const KendoDateCell = (props) => {
  const value = props.dataItem[props.field];
  return <td>{formatDate(new Date(value), "dd-MMM-yyyy")}</td>;
};

const DetailComponent = (props) => {
  const navigate = useNavigate();
  const po = props.dataItem;
  return (
    <section>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 text-left text-xs font-semibold text-gray-900"
            >
              LxWxH
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-1 py-2 text-left text-xs font-semibold text-gray-900"
            >
              Qty
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
            >
              Weight
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
            >
              Volume
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
            >
              Ch. Weight
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-2 w-fit truncate text-left text-xs font-semibold text-gray-900"
            >
              Active Job No
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
            >
              Location
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
            >
              Warehouse
            </th>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-4"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {po.packages.map((pkg) => (
            <tr key={pkg.id}>
              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-900">
                {pkg.length}x{pkg.width}x{pkg.height} cm
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-900">
                {pkg.colli}
              </td>

              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                {pkg.weight} kg
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                {pkg.volume.toFixed(2)} m3
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                {pkg.chargableWeight.toFixed(2)} kg
              </td>
              <td
                className={`${
                  pkg.activeJobId
                    ? "text-cyan-500 underline cursor-pointer"
                    : "text-gray-500"
                } whitespace-nowrap px-2 py-2 text-xs`}
                onClick={() => {
                  if (pkg.activeJobId) navigate(`/job/${pkg.activeJobId}`);
                }}
              >
                {pkg.jobNo}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                {pkg.location}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                {pkg.currentWarehouseName}
              </td>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs text-gray-500 sm:pl-6">
                {
                  movementStatuses.find((x) => x.id === pkg.movementStatus)
                    ?.name
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

const columns = [
  {
    field: "poNo",
    title: "Po No",
    show: true,
    width: "150",
    filterable: true,
    locked: true,
  },
  {
    field: "vesselName",
    title: "Vessel",
    show: true,
    width: "200",
    filterable: true,
  },
  {
    field: "customerName",
    title: "Customer",
    show: true,
    width: "200",
    filterable: true,
  },
  {
    field: "supplierName",
    title: "Supplier",
    show: true,
    width: "200",
    filterable: true,
  },
  {
    field: "shipmentRefNo",
    title: "AWB/BL",
    show: true,
    filterable: true,
    width: 135,
  },
  {
    field: "totalValue",
    title: "Value",
    show: true,
    width: 120,
    filterable: false,
  },
  {
    field: "receivedDate",
    title: "Received",
    show: true,
    width: "150",
    filterable: false,
    cell: KendoDateCell,
  },
  {
    field: "creationDate",
    title: "Created",
    show: true,
    width: "160",
    filterable: false,
    cell: KendoDateCell,
  },
];

export default function PoList() {
  let navigate = useNavigate();
  const { filterValue } = useParams();
  const defaultsearchTexts = {
    location: "~ALL",
    poNo: "~ALL",
    vesselName: "~ALL",
    customerName: "~ALL",
    supplierName: "~ALL",
    shipmentRefNo: "~ALL",
  };
  const [loading, setLoading] = useState(false);
  const [polist, setPoList] = useState([]);
  const [state, setState] = useState(false);
  const [searchLocation, setSearchLocation] = useState();
  const [searchTexts, setSearchTexts] = useState(defaultsearchTexts);
  const [pageState, setPageState] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 10,
  });
  const [gridData, setGridData] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 10,
    //total: 0,
  });

  const [stateColumns, setStateColumns] = React.useState(columns);
  const authCtx = useContext(AppContext);
  const _export = React.useRef(null);
  const excelExport = async () => {
    let location = "~ALL";
    if (searchTexts.location !== "") {
      location = searchTexts.location;
    }
    let filterApproved = false;
    if (filterValue === "notApproved") {
      filterApproved = true;
    }
    const res = await axios.get(
      `${BASE_URL}/Cargo?searchText=${encodeURIComponent(
        location
      )}&poNo=${encodeURIComponent(
        searchTexts.poNo
      )}&vessel=${encodeURIComponent(
        searchTexts.vesselName
      )}&customer=${encodeURIComponent(
        searchTexts.customerName
      )}&supplier=${encodeURIComponent(
        searchTexts.supplierName
      )}&awb=${encodeURIComponent(
        searchTexts.shipmentRefNo
      )}&filterApproved=${filterApproved}&pageNo=0&pageSize=1000`
    );
    if (res.data.data !== null) {
      _export.current.save(res.data.data);
    }
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const handleGridDataStateChange = (e) => {
    setPageState(e.dataState);
  };

  const [selectedState, setSelectedState] = React.useState({});
  const [selectedPos, setSelectedPos] = useState([]);
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const index = selectedPos.findIndex((x) => x.id === event.dataItem.id);

      if (index >= 0) {
        setSelectedPos((selectedPos) =>
          selectedPos.filter((_, i) => i !== index)
        );
      } else {
        setSelectedPos((oldArray) => [...oldArray, event.dataItem]);
      }
    },
    [selectedState]
  );

  const onLocationChange = (e) => {
    setSearchTexts((pre) => ({ ...pre, location: e.target.value }));
    setSearchLocation(e.target.value);
  };

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedPos(event.dataItems);
    setSelectedState(newSelectedState);
  }, []);

  const loadData = async (state) => {
    setLoading(true);
    let location = "~ALL";
    if (searchTexts.location !== "") {
      location = searchTexts.location;
    }
    let filterApproved = false;
    if (filterValue === "notApproved") {
      filterApproved = true;
    }
    const res = await axios.get(
      `${BASE_URL}/Cargo?searchText=${location}&poNo=${searchTexts.poNo}&vessel=${searchTexts.vesselName}&customer=${searchTexts.customerName}&supplier=${searchTexts.supplierName}&awb=${searchTexts.shipmentRefNo}&filterApproved=${filterApproved}&pageNo=${state.skip}&pageSize=${state.take}`
    );
    let data = res.data.data;
    setPoList(data);
    setGridData((pre) => ({
      ...pre,
      total: res.data.totalCount,
      take: res.data.pageSize,
      skip: res.data.pageNo,
    }));
    setLoading(false);
  };

  const onExpandChange = (event) => {
    let newData = polist.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setPoList(newData);
  };

  useEffect(() => {
    if (authCtx.profile.role === 300) {
      setSearchLocation(authCtx.profile.warehouse.cityName);
    }
  }, []);

  useEffect(() => {
    const debouncedLoadData = debounce((data) => {
      loadData(data);
    }, 300); // 300ms debounce time

    debouncedLoadData(gridData);

    return () => {
      debouncedLoadData.cancel(); // Cleanup on unmount or dependency change
    };
  }, [searchTexts]);

  const onEditClick = (e) => {
    const editedItem = e.dataItem;
    navigate(`/po/${editedItem.id}`);
  };

  const newPoClick = () => {
    navigate(`/po/new`);
  };

  const onGridFilter = (e) => {
    if (e.filter !== null) {
      if (e.filter && e.filter.filters && e.filter.filters[0].value !== "") {
        setSearchTexts((pre) => ({
          ...pre,
          [e.filter.filters[0].field]: e.filter.filters[0].value,
        }));
      } else {
        setSearchTexts((pre) => ({
          ...pre,
          [e.filter.filters[0].field]: "~ALL",
        }));
      }
      const state = {
        ...gridData,
        filter: { ...e.filter },
        skip: 0,
      };
      setGridData(state);
    } else {
      setSearchTexts(defaultsearchTexts);
      const state = {
        ...gridData,
        filter: [
          {
            field: "poNo",
            operator: "contains",
            value: "",
          },
        ],
        skip: 0,
      };
      setGridData(state);
    }
  };

  const onCreateClick = () => {
    setState(true);
  };

  const onPageChange = (e) => {
    const state = { ...gridData, skip: e.page.skip, take: e.page.take };
    setGridData(state);
    loadData(state);
  };

  const rowRender = (trElement, props) => {
    const approved = props.dataItem.isApproved;
    const isDGCargo = props.dataItem.cargoType === 200;
    let style = {};
    if (!approved)
      style = {
        backgroundColor: "rgb(255, 165, 0, 0.40)",
      };
    if (isDGCargo) style = { ...style, color: "rgb(255,0,0)", fontWeight: 500 };
    const trProps = { style: style };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuSort {...props} />
        <GridColumnMenuCheckboxFilter data={stateColumns} {...props} />
        {/* <GridColumnMenuFilter {...props} /> */}
      </div>
    );
  };
  return (
    <div className="mx-4 my-4 sm:mx-6 lg:mx-8">
      <div className="flex mb-4 justify-between">
        <div className="flex">
          <div className="flex-col min-w-0">
            <h1 className="text-xl font-semibold leading-6 text-gray-900 sm:truncate">
              Purchase Order
            </h1>
            <div className="text-xs">Voyage Marine Logistics</div>
          </div>
          <div className="w-14 h-6 text-center p-1 bg-zinc-300 rounded-xl text-sm font-medium ml-4 mt-1">
            {gridData.total}
          </div>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-4 gap-2 items-center">
          <div className="flex text-xs text-gray-900 font-medium items-center gap-2 relative">
            <label>Location</label>
            <input
              type="text"
              name="location"
              className="w-56 h-7 rounded-md shadow-sm border border-slate-300 text-neutral-700 text-sm font-normal"
              value={searchLocation}
              onChange={(e) => onLocationChange(e)}
            />
            <button
              className="absolute right-2 px-1 h-full rounded-tr-md rounded-br-md"
              type="button"
              onClick={() => {
                setSearchTexts((pre) => ({ ...pre, location: "" }));
                //loadData(searchTexts, gridData);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="gray"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="order-1 flex items-center justify-center h-7 w-20 border border-zinc-300 shadow-sm text-xs font-semibold rounded-sm text-black bg-white hover:bg-gray-50 focus:outline-none sm:order-0 sm:ml-0 disabled:opacity-30"
              onClick={onCreateClick}
              disabled={selectedPos.length <= 0 ? true : false}
            >
              Actions
            </button>
          </div>
          <button
            title="Export Excel"
            className="flex h-7 w-24 bg-white text-center justify-center items-center text-xs font-semibold gap-2 border border-zinc-300 rounded-sm"
            onClick={excelExport}
          >
            <img src="export.svg" alt="export" />
            Export
          </button>
          <button
            type="button"
            className="inline-flex h-9 w-32 items-center justify-center border-2 shadow-sm text-xs gap-2 font-semibold rounded-sm text-white bg-lime-600 focus:outline-none  sm:order-1"
            onClick={newPoClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Add New PO
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="-my-2 overflow-x-auto w-full">
          <div className="py-2 align-middle w-full inline-block min-w-full">
            <div className="overflow-hidden po w-full relative rounded-lg shadow-[0_0_4px_rgba(0,0,0,0.1)]">
              {loading && <KendoLoader />}
              <ExcelExport data={polist} ref={_export}>
                <Grid
                  data={process(
                    polist.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    })),
                    pageState
                  )}
                  pageable={{
                    pageSizes: true,
                  }}
                  detail={DetailComponent}
                  filterable={true}
                  sortable={true}
                  resizable={true}
                  reorderable={true}
                  onFilterChange={onGridFilter}
                  onDataStateChange={handleGridDataStateChange}
                  onRowDoubleClick={onEditClick}
                  rowRender={rowRender}
                  onExpandChange={onExpandChange}
                  expandField="expanded"
                  dataItemKey={DATA_ITEM_KEY}
                  onPageChange={onPageChange}
                  total={gridData.total}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  onSelectionChange={onSelectionChange}
                  onHeaderSelectionChange={onHeaderSelectionChange}
                  {...gridData}
                  style={{
                    borderRadius: "10px",
                    borderColor: "#E5E7EB",
                    fontSize: "12px",
                    fontFamily: "poppins",
                    zIndex: 0,
                    height: "77.5vh",
                    width: "100%",
                  }}
                >
                  <GridColumn
                    filterable={false}
                    width="70px"
                    field={SELECTED_FIELD}
                    locked
                    headerSelectionValue={
                      polist.findIndex(
                        (item) => !selectedState[idGetter(item)]
                      ) === -1
                    }
                  />
                  {stateColumns.map(
                    (column, idx) =>
                      column.show && (
                        <GridColumn
                          key={idx}
                          locked={column.locked}
                          field={column.field}
                          title={column.title}
                          filterable={column.filterable}
                          cell={column.cell}
                          selectable={true}
                          width={column.width}
                          columnMenu={ColumnMenu}
                        />
                      )
                  )}
                </Grid>
              </ExcelExport>
            </div>
          </div>
        </div>
      </div>
      <PoActionsSlider
        show={state}
        setOpen={setState}
        selectedPos={selectedPos}
      />
    </div>
  );
}
