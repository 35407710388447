import * as React from "react";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
export const DropDownCell = (props) => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };
  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = { id: dataItem.customerId, name: dataItem.customerName };
  return (
    <td>
      {dataItem.inEdit ? (
        <div className="h-9 w-full" id="poCompo">
          <ComboBox
            textField="name"
            dataItemKey="id"
            onChange={handleChange}
            value={dataValue}
            data={props?.customers || []}
            className="sm:text-sm focus:ring-0 w-full focus:border-0 py-2 px-3 h-full p-0"
            placeholder="Select Customer"
          />
        </div>
      ) : dataValue ? (
        dataValue?.name
      ) : (
        ""
      )}
    </td>
  );
};
