import React, { useState, useEffect } from "react";
import BaseMasterGrid from "../BaseMasterGrid";
import CitySlider from "./CitySlider";
import axios from "axios";
import { BASE_URL } from "../../../constants";
import KendoLoader from "../../KendoLoader";

const CityMaster = () => {
  const defaultEntity = {
    code: "",
    name: "",
    country: "",
  };
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [city, setCity] = useState(defaultEntity);
  const [pageState, setPageState] = useState({
    take: 10,
    skip: 0,
    total: 0,
    logic: "and",
    filters: [
      {
        field: "name",
        operator: "contains",
        value: "",
      },
    ],
  });

  const gridColumns = [
    { field: "id", title: "ID", hidden: true },
    {
      field: "code",
      title: "Code",
      filterable: true,
      sortable: true,
      hidden: true,
      
    },
    { field: "name", title: "Name", filterable: true, sortable: true, width:"150" },
    { field: "countryName", title: "Country" },
  ];

  const loadData = async (searchText, state) => {
    setLoading(true);
    const res = await axios.get(
      `${BASE_URL}/City/getallcity?searchText=${searchText}&skip=${state.skip}&take=${state.take}`
    );
    setEntities(res.data.data);
    setPageState((pre) => ({
      ...pre,
      total: res.data.totalCount,
      skip: res.data.pageNo,
      take: res.data.pageSize,
      value: searchText,
    }));
    setLoading(false);
  };
  const onUpdate = (item) => {
    setEntities(entities.map((c) => (c.id === item.id ? { ...item } : c)));
  };

  const onGridFilter = (e) => {
    let searchText = "";
    if (e.filter && e.filter.filters && e.filter.filters[0].value !== "") {
      searchText = e.filter.filters[0].value;
    } else {
      searchText = "~ALL";
    }
    const state = {
      ...pageState,
      filter: { ...e.filter },
      skip: 0,
      value: searchText,
    };
    setPageState(state);
    loadData(searchText, state);
  };

  const onCreate = (item, id) => {
    setEntities([...entities, { ...item, id: id }]);
  };
  const onCreateClick = () => {
    setCity(defaultEntity);
    setOpen(true);
  };
  const onEditClick = (e) => {
    setCity({
      ...e.dataItem,
      country: { code: e.dataItem.countryCode, name: e.dataItem.countryName },
    });
    setOpen(true);
  };

  useEffect(() => {
    const searchText = "~ALL";
    loadData(searchText, pageState);
  }, []);

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">City</h3>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onCreateClick}
          >
            Create
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow relative overflow-hidden sm:rounded-lg">
              {loading && <KendoLoader />}
              <BaseMasterGrid
                data={entities}
                columns={gridColumns}
                onEditClick={onEditClick}
                setPageState={setPageState}
                loadData={loadData}
                pageState={pageState}
                onGridFilter={onGridFilter}
              ></BaseMasterGrid>
            </div>
          </div>
        </div>
      </div>
      <CitySlider
        open={open}
        setOpen={setOpen}
        city={city}
        setCity={setCity}
        onUpdate={onUpdate}
        onCreate={onCreate}
      />
    </div>
  );
};

export default CityMaster;
