import React, { useState } from "react";
import { dateFieldName, formatDate } from "@telerik/kendo-intl";
import AutoResizeTextarea from "./AutoResizeTextArea";

const PerformaInvoice = ({
  job,
  handleChange,
  handlePoChange,
  handleAreaChange,
}) => {
  const [isEditable, setIsEditable] = useState(true);

  const getUniqueObjects = (array, key) => {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );
  };

  return (
    <div>
      <div class="border-2 border-black w-[700px] min-h-[950px] h-fit px-3 py-3 pb-20 relative port printDoc">
        <div class="flex justify-start w-full">
          <img src="vml_logo.png" alt="voyagelogo" className="h-20" />
        </div>
        <div class="w-full text-center text-lg font-medium pb-5">
          <h1>VOYAGE MARINE LOGISTICS PTE. LTD.</h1>
          <p class="text-[10px] font-normal leading-4">
            Incorporated in the Republic of Singapore (Company Registration No.
            202029304Z)
            <br />
            Registered Address: 190 Middle Road #13-01, Fortune Centre,
            Singapore188979
          </p>
          <h3 class="font-bold mt-2">PROFORMA INVOICE</h3>
        </div>
        <div class="flex w-full justify-between">
          <div class="flex flex-col w-96 font-bold text-xs">
            <div class="flex pb-2 w-full">
              <label class="w-32">SHIPPER</label>:&nbsp;
              <div className="flex flex-col w-full font-normal">
                <input
                  class="font-normal w-full editable"
                  name="shipperName"
                  onChange={handleChange}
                  value={job?.shipperName}
                />
                <br />
                ON BEHALF OF
              </div>
            </div>
            <div class="flex pb-2">
              <label class="w-32">PRINCIPAL</label>
              :&nbsp;
              <input
                class="font-normal w-full editable"
                name="customerName"
                onChange={handleChange}
                value={job?.customerName}
              />
              {/* <div class="font-normal editable" contentEditable={isEditable}>
                {job?.customerName}
              </div> */}
            </div>
            <div class="flex pb-2">
              <label class="w-32">SHIP TO</label>:&nbsp;
              <div class="font-bold flex justify-between pb-1 flex-col w-full">
                <textarea
                  class="font-normal resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                  name="consigneeName"
                  onChange={handleChange}
                  value={job?.consigneeName}
                />
                <textarea
                  class="font-normal resize-none overflow-clip w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                  name="consigneeAddress"
                  rows={7}
                  onChange={(e) => handleAreaChange(e, 7)}
                  value={job?.consigneeAddress}
                />
                {/* <div class="flex flex-col justify-between mb-1 editable">
                  <div>{job?.consigneeName}</div>
                  <div>{job?.consigneeAddress}</div>
                </div> */}
                {/*  */}
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-between text-xs font-bold h-full">
            <div class="flex pb-1">
              <label class="w-16">DATE</label>
              :&nbsp;
              {/* <input
                class="font-normal editable"
                name="currentDate"
                onChange={handleChange}
                value={job?.currentDate}
              /> */}
              <div class="font-normal editable" contentEditable={isEditable}>
                {formatDate(new Date(), "dd-MM-yyyy")}
              </div>
            </div>
            <div class="flex pb-1">
              <label class="w-16">Inv No</label>
              :&nbsp;
              <input
                class="font-normal editable"
                name="jobNo"
                onChange={handleChange}
                value={job?.jobNo}
              />
              {/* <div class="font-normal editable" contentEditable={isEditable}>
                {job?.jobNo}
              </div> */}
            </div>
            <div class="flex pb-1">
              <label class="w-16">Ref No</label>
              :&nbsp;
              <input
                class="font-normal editable"
                name="jobNo"
                onChange={handleChange}
                value={job?.jobNo}
              />
              {/* <div class="font-normal editable" contentEditable={isEditable}>
                {job?.jobNo}
              </div> */}
            </div>
          </div>
        </div>
        <div class="flex flex-col font-bold text-sm pb-2">
          <div className="flex">
            <label class="w-56 truncate">CARGO DESCRIPTION</label>
            :&nbsp;
            <input
              class="font-normal w-full editable"
              name="jobNo"
              onChange={handleChange}
              value={job?.cargoDescription}
            />
            {/* <label
                className="font-normal editable"
                contentEditable={isEditable}
              >
                SHIP SPARES IN TRANSIT FOR
              </label> */}
          </div>
          <div class="flex">
            <div className="w-20 truncate">HS CODE</div>
            :&nbsp;
            <input
              class="font-normal w-full editable"
              name="HsCode"
              onChange={handleChange}
              value={job?.HsCode}
            />
            {/* <div
                contentEditable={isEditable}
                className=" min-w-[20%] editable"
              ></div> */}
          </div>
          {/* <input
              class="font-normal editable"
              name="remarks"
              onChange={handleChange}
              value={job?.remarks}
            /> */}
          {/* <label class="w-16 editable" contentEditable={isEditable}>
              {job?.remarks}
            </label> */}
          {/* <div class="flex gap-4">
            <label class=""></label>
            <div></div>
          </div> */}
        </div>
        <div class="w-full">
          <table class="w-full border border-black text-sm">
            <thead class="bg-slate-300 w-full">
              <tr>
                <th class="border-l border-black px-1">No</th>
                <th class="border-l border-black px-1">SUPPLIERS</th>
                <th class="border-l border-black px-1">PO NUMBERS</th>
                <th class="border-l border-black px-1">CARGO DESCRIPTION</th>
                <th class="border-l border-black px-1">COO</th>
                {/* <th class="border-l border-black">QTY</th> */}
                <th class="border-l border-black ">
                  AMOUNT ({job?.poes[0]?.currencyCode})
                </th>
              </tr>
            </thead>
            <tbody class="border">
              {job &&

                getUniqueObjects(job.poes, "id").map((po, index) => (

                  <tr class="h-auto border-black border">
                    <td class="border-l border-black pl-1">{index + 1}</td>
                    <td
                      class="border-l h-auto align-middle px-1 py-1 text-xs editable border-black editable"
                      // id="supplierName"
                      // contentEditable={isEditable}
                      // onInput={(e) => handlePoChange(e, po.id)}
                    >
                      <AutoResizeTextarea
                        text={po.supplierName}
                        handleChange={handlePoChange}
                        name="supplierName"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                      {/* <textarea
                        class="font-normal resize-y  py-3 overflow-hidden pl-1 h-10 w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                        name="supplierName"
                        onChange={handlePoChange}
                        value={po.supplierName}
                      /> */}
                      {/* {po.supplierName} */}
                    </td>
                    <td
                      class="border-l h-auto align-middle px-1 py-1 text-xs editable border-black editable"
                      // id="poNo"
                      // contentEditable={isEditable}
                      // onInput={(e) => handlePoChange(e, po.id)}
                    >
                      <AutoResizeTextarea
                        text={po.poNo}
                        handleChange={handlePoChange}
                        name="poNo"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                      {/* <textarea
                        class="font-normal resize-none py-3 overflow-hidden pl-1 h-10 w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                        name="poNo"
                        onChange={handlePoChange}
                        value={po.poNo}
                      /> */}
                      {/* {po.poNo} */}
                    </td>
                    <td
                      class="border-l h-auto align-middle px-1 py-1 text-xs editable border-black editable"
                      // id="commodity"
                      // contentEditable={isEditable}
                      // onInput={(e) => handlePoChange(e, po.id)}
                    >
                      <AutoResizeTextarea
                        text={po?.commodity}
                        handleChange={handlePoChange}
                        name="commodity"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                      {/* <textarea
                        class="font-normal resize-none overflow-hidden pl-1 h-full align-middle w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                        name="commodity"
                        onChange={handlePoChange}
                        value={po.commodity}
                      /> */}
                      {/* {po?.commodity} */}
                    </td>
                    <td
                      class="border-l h-auto align-middle px-1 py-1 text-xs editable border-black editable"
                      // id="cooName"
                      // contentEditable={isEditable}
                      // onInput={(e) => handlePoChange(e, po.id)}
                    >
                      <AutoResizeTextarea
                        text={po.cooName}
                        handleChange={handlePoChange}
                        name="cooName"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                      {/* <textarea
                        class="font-normal resize-none py-3 overflow-hidden pl-1 h-10 w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                        name="cooName"
                        onChange={handlePoChange}
                        value={po.cooName}
                      /> */}
                      {/* {po?.cooName} */}
                    </td>
                    {/* <td class="border-l border-black">{pkg.qty}</td> */}
                    <td
                      class="border-l h-auto align-middle px-1 py-1 text-xs editable border-black editable"
                      // id="totalValue"
                      // contentEditable={isEditable}
                      // onInput={(e) => handlePoChange(e, po.id)}
                    >
                      <AutoResizeTextarea
                        text={po.totalValue}
                        handleChange={handlePoChange}
                        name="totalValue"
                        style="font-normal resize-none overflow-clip h-auto py-1 border-0 focus:ring-0 p-0 leading-3 text-xs w-full"
                        poId={po.id}
                      />
                      {/* <textarea
                        class="font-normal resize-none py-3 overflow-hidden pl-1 h-10 w-full border-0 focus:ring-0 p-0 leading-3 text-xs editable"
                        name="totalValue"
                        onChange={handlePoChange}
                        value={po.totalValue}
                      /> */}
                      {/* {po.totalValue} */}
                    </td>
                  </tr>
                ))}
              <tr class="h-5 font-bold">
                <td class="border border-black"></td>
                <td class="border border-black"></td>
                <td class="border border-black"></td>
                <td class="border border-black bg-slate-300">TOTAL:</td>
                <td class="border border-black bg-slate-300">
                  {job?.currencyCode}
                </td>
                <td class="border border-black bg-slate-300">
                  {job &&

                    job?.poes &&
                    getUniqueObjects(job?.poes, "id")

                      .reduce(
                        (total, po) => total + parseFloat(po.totalValue),
                        0
                      )
                      ?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex font-bold pt-2 justify-between text-xs">
            <div>No Commercial Value. Value for Customs Purposes only.</div>
          </div>
          {/* <div class="font-bold mt-1 flex justify-between text-sm">
            <div>COMPUTER GENERATED. NO SIGNATURE REQUIRED</div>
          </div> */}
        </div>

        <footer class="text-[10px] font-semibold pt-1 absolute bottom-1">
          COMPUTER GENERATED. NO SIGNATURE REQUIRED
          <br />
          Voyage Marine Logistics Pte Ltd(VML) provides the services "As Agent
          Only".VML acts on behalf of Shipping Principals In "An Agent-Only"
        </footer>
      </div>
    </div>
  );
};

export default PerformaInvoice;
