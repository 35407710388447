import { ReactFlow, Controls, Background, MiniMap } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useState } from 'react';

function CreatePlanner() {
    const [nodes, setNodes] = useState([
        
    ]);
    const [edges, setEdges] = useState([{ id: '1-2', source: '1', target: '2' }]);
    const [nodeId, setNodeId] = useState(3); // to track new node IDs

    const addNode = () => {
        const newNode = {
            id: `${nodeId}`,
            position: { x: Math.random() * 400, y: Math.random() * 400 }, // Random position
            data: { label: `Node ${nodeId}` },
        };
        setNodes((prevNodes) => [...prevNodes, newNode]);
        setNodeId((prevId) => prevId + 1); // Increment node ID
    };

    const onNodeClick = (event, node) => {
        const newEdge = {
            id: `${node.id}-${nodeId}`,
            source: node.id,
            target: `${nodeId}`,
        };
        setEdges((prevEdges) => [...prevEdges, newEdge]);
        addNode(); // Optionally add a new node when connecting
    };

    return (
        <div style={{ height: '100%' }}>
            <button onClick={addNode} style={{ marginBottom: '10px' }}>Add Node</button>
            <ReactFlow nodes={nodes} edges={edges} onNodeClick={onNodeClick}>
                <Background />
                <Controls />
                <MiniMap />
            </ReactFlow>
        </div>
    );
}

export default CreatePlanner;
