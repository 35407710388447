import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import React, { useState, useEffect, useContext } from "react";
import JobForm from "./JobForm";
import Events from "./Events";
import PackageDetails from "./PackageDetails";
import JobPoes from "./JobPoes";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL, jobStatus } from "../../constants";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { UserIcon, CalendarIcon } from "@heroicons/react/solid";
import { formatDate } from "@telerik/kendo-intl";
import { DocumentForm } from "../po/DocumentForm";
import { confirmAlert } from "react-confirm-alert";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import JobExtras from "./JobExtras";
import AppContext from "../../context/app-context";
import KendoLoader from "../KendoLoader";

const JobDetails = () => {
  const navigate = useNavigate();
  const defaultJob = {
    customer: "",
    vessel: "",
    mot: 100,
    whOrigin: "",
    shipper: "",
    shipperAddress: "",
    consignee: "",
    consigneeAddress: "",
    destinPort: "",
    originPort: "",
    etd: null,
    eta: null,
    remarks: "",
    Whdestin: "",
    documents: [],
    poes: [],
    packages: [],
    jobType: "",
    notificationParty: "",
    jobDate: null,
    jobNo: "",
    awbno: "",
    packageDetailsQuantity: "0",
    packageDetailsGrossWeight: "0",
    packageDetailsUom: 100,
    packageDetailsChargableWeight: "0",
    jobStatus: "Active",
    jobStatusData: jobStatus.find((s) => s.name === "Active"),
    whShipmentMode: "import",
  };

  let { id } = useParams();
  const [show, setShow] = useState({
    pdf: false,
    status: false,
    loading: false,
  });
  const authCtx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState(defaultJob);
  const [formDisabled, setFormDisabled] = useState(false);
  const [modified, setModified] = useState(false);
  const [docState, setDocState] = useState(-1);
  const links = [
    { id: 2, label: "VML Delivery Note" },
    { id: 4, label: "Manifest/Packing List" },
    { id: 5, label: "JOB INSTRUCTIONS(SEAFREIGHT)" },
    { id: 6, label: "VML PERFOMA INVOICE" },
    { id: 7, label: "Master B/L Draft" },
    { id: 8, label: "VML HBL" },
  ];

  const onClickAction = (e, doc) => {
    navigate(`/printdocJob/${doc.id}/${id}`);
  };

  const onLoad = async () => {
    setLoading(true);
    const res = await axios.get(`${BASE_URL}/Job/${id}`);
    if (res.status === 200) {
      setJob({
        ...res.data,
        id: res.data.id,
        customer: { id: res.data.customerId, name: res.data.customerName },
        vessel: { id: res.data.vesselId, name: res.data.vesselName },
        originAgent: {
          id: res.data.originAgentId,
          name: res.data.originAgentName,
        },
        destinationAgent: {
          id: res.data.destinationAgentId,
          name: res.data.destinationAgentName,
        },
        shipper: { id: res.data.shipperId, name: res.data.shipperName },
        consignee: { id: res.data.consigneeId, name: res.data.consigneeName },
        destinPort: {
          code: res.data.destinationPortCode,
          name: res.data.destinationPortName,
        },
        originPort: {
          code: res.data.originPortCode,
          name: res.data.originPortName,
        },
        destinationWarehouse: {
          id: res.data.destinWarehouseId,
          name: res.data.destinWarehouseName,
        },
        etd: res.data.etd ? new Date(res.data.etd) : null,
        eta: res.data.eta ? new Date(res.data.eta) : null,
        notificationParty: {
          id: res.data.notificationPartyCode,
          name: res.data.notificationPartyName,
        },
        creationDate: res.data.creationDate
          ? new Date(res.data.creationDate)
          : null,
        jobDate: res.data.jobDate ? new Date(res.data.jobDate) : null,
        poes: res.data.poes.filter((poe) => poe.package !== null),
        packages: [
          ...res.data.poes
            .filter((poe) => poe.package !== null)
            .map((po) => ({
              cargoId: po?.id,
              packageID: po?.package.id,
            })),
        ],
        packageDetailsUom:
          res.data.packageDetailsUom === null
            ? 100
            : res.data.packageDetailsUom,
      });
      await onStatusChange(res.data.jobStatus);
    } else {
      toast.error("An error occured");
    }
    setLoading(false);
  };

  const onDocumentUploaded = (newDocs) => {
    const jobDocs = newDocs.map((doc) => {
      return {
        documentID: doc.id,
        jobID: job.id,
        fileName: doc.fileName,
        size: doc.size,
      };
    });
    setJob({ ...job, documents: [...job.documents, ...jobDocs] });
    setDocState(docState + 1);
  };

  const saveJob = async () => {
    setShow({ ...show, loading: true });
    if (id !== "new") {
      const res = await axios.put(`${BASE_URL}/Job`, job);
      if (res.status === 200) {
        setModified(false);
        toast.success("JOB Updated successfully!");
        if (job.jobStatus === "Canceled") {
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith(job.jobNo)) {
              localStorage.removeItem(key);
            }
          }
        }
        setShow({ ...show, loading: false });
      } else {
        toast.error("An error occured while Updating JOB!");
        setShow({ ...show, loading: false });
      }
    } else {
      const res = await axios.post(`${BASE_URL}/Job/CreateOWHJob`, job);
      if (res.status === 200) {
        toast.success("JOB created successfully!");
        setShow({ ...show, loading: false });
        setJob({ ...job, ...res.data });
        navigate(`/job/${res.data.id}`);
        // document.location.reload();
      } else {
        toast.error("An error occured while creating PO!");
        setShow({ ...show, loading: false });
      }
    }
  };

  const OnSubmit = async (e) => {
    e.preventDefault();
    saveJob();
  };

  const onDocumentDeleted = (docId) => {
    const exDocIndex = job.documents.findIndex((x) => x.documentID === docId);
    job.documents.splice(exDocIndex, 1);
    setJob({ ...job, documents: [...job.documents] });
    saveJob(job);
  };

  useEffect(() => {
    if (docState >= 0) saveJob();
  }, [docState]);

  const onCloseClick = () => {
    if (modified === true) {
      confirmAlert({
        title: "Discard Changes",
        message: "You have unsaved changes.Do you want to Continue.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              navigate(`/jobs`);
            },
          },
          {
            label: "No",
          },
        ],
      });
    } else {
      navigate(`/jobs`);
    }
  };

  const onStatusChange = (status) => {
    setModified(true);
    setJob((pre) => ({ ...pre, jobStatus: status }));
    if (status === "Canceled" || status === "Closed") setFormDisabled(true);
    else setFormDisabled(false);
    setShow({ ...show, status: false });
  };

  useEffect(() => {
    if (id !== "new") onLoad(id);
    else {
      if (authCtx.profile.role === 300)
        setJob({
          ...job,
          createdUserName: authCtx.profile.displayname,
          originAgent: {
            id: authCtx.profile.warehouse.agentId,
            name: authCtx.profile.warehouse.agentName,
          },
          origin: authCtx.profile.warehouse.cityName,
          originAgentId: authCtx.profile.warehouse.agentId,
          creationDate: new Date(),
          jobType: 400,
        });
      else
        setJob({
          ...job,
          createdUserName: authCtx.profile.displayname,
          creationDate: new Date(),
          jobType: 400,
        });
    }
  }, []);

  return (
    <div className="flex w-full h-full bg-white overflow-hidden relative">
      {loading && <KendoLoader />}
      <div className="w-full h-full flex-col py-3">
        <div className="flex justify-between items-center bg-sky-200 h-10 rounded-lg px-4 mx-5">
          <div className="flex font-medium items-center">
            <div className="flex max-w-fit truncate">
              <h2 className="text-sm ml-1 text-slate-800 font-medium flex">
                {job.jobType && job.jobType === 100
                  ? "WH-WH"
                  : job.jobType === 200
                  ? "WH-V"
                  : job.jobType === 300
                  ? "WH-D"
                  : job.jobType === 400 && "O-WH"}
              </h2>
            </div>
            <hr className="rotate-90 w-5 border-zinc-300"></hr>
            <div className="text-sm ml-1 text-slate-800">
              Job Number :
              <label className="text-black ml-1">
                {id !== "new" ? (
                  job.jobNo && job.jobNo
                ) : (
                  <label className="text-orange-600">New Job</label>
                )}
              </label>
            </div>
          </div>
          <div className="flex items-center text-xxs font-medium">
            <div className="flex items-center text-slate-800">
              <UserIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-sky-600"
                aria-hidden="true"
              />
              Created By :&nbsp;
              <label className="text-neutral-700">{job.createdUserName}</label>
            </div>
            <hr className="rotate-90 w-5 border-zinc-300"></hr>
            <div className="flex items-center text-slate-800">
              <CalendarIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-sky-600"
                aria-hidden="true"
              />
            </div>
            <div className="text-slate-600">
              Created On :&nbsp;
              <label className="text-neutral-700">
                {formatDate(new Date(job.creationDate), "yyyy-MM-dd")}
              </label>
            </div>
          </div>
        </div>
        <div className="h-full">
          <Tabs className="po-tabs">
            <div className="w-full sticky top-16 flex justify-between items-center pb-3 bg-white px-5">
              <TabList className="po-tab border-0 flex justify-center items-center">
                <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3">
                  Details
                </Tab>
                {job && job.id && (
                  <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3.5">
                    PO
                  </Tab>
                )}
                {job && job.id && (
                  <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3.5">
                    Documents
                  </Tab>
                )}
                {/* <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3.5">
                  Extras
                </Tab> */}
              </TabList>
              <div className="relative flex gap-3">
                <div className="relative">
                  <button
                    id="dropdownDefault"
                    data-dropdown-toggle="dropdown"
                    class={`${
                      job.jobStatus === "Active"
                        ? "bg-sky-500 border-sky-600"
                        : job.jobStatus === "Closed"
                        ? "bg-green-500 border-green-600"
                        : "bg-red-500 border-red-600"
                    } flex h-9 rounded-md border-2   text-white w-28 font-semibold text-sm justify-center items-center gap-2 cursor-pointer`}
                    type="button"
                    onClick={() => {
                      setShow({ ...show, status: !show.status, pdf: false });
                    }}
                  >
                    {job.jobStatus}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      class="w-3 h-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                  {show.status && (
                    <div class="z-50 w-32 h-fit overflow-hidden border border-gray-200 absolute top-9 divide-y divide-gray-300 origin-top-right right-0 mt-2 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <ul
                        class=" text-sm text-gray-700"
                        aria-labelledby="dropdownDefault"
                      >
                        {jobStatus.map((i) => (
                          <li key={i.id}>
                            <button
                              id={i.id}
                              type="submit"
                              class="hover:bg-gray-200 px-4 py-3 w-full text-left text-sm text-gray-700 border-b flex items-center gap-3 disabled:cursor-not-allowed disabled:hidden"
                              onClick={() => onStatusChange(i.name)}
                              disabled={
                                (i.enableEvents &&
                                  !job.events?.find((e) =>
                                    i.enableEvents.includes(
                                      e.logisticsEventTypeId
                                    )
                                  )) ||
                                (authCtx &&
                                  i.enabledRoles &&
                                  !i.enabledRoles.includes(
                                    authCtx.profile.role
                                  ))
                              }
                            >
                              {i.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div>
                  <button
                    id="dropdownDefault"
                    data-dropdown-toggle="dropdown"
                    class="flex h-9 rounded-md border-2 border-sky-600 bg-sky-600 text-white w-28 font-semibold text-sm justify-center items-center gap-2 cursor-pointer"
                    type="button"
                    onClick={() => {
                      setShow({ ...show, pdf: !show.pdf, status: false });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                      />
                    </svg>
                    Print
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      class="w-3 h-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </button>
                  {show.pdf && (
                    <div class="z-50 w-64 h-80 overflow-scroll border border-gray-200 absolute top-9 divide-y divide-gray-300 origin-top-right right-60 mt-2 rounded-md shadow-2xl py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <ul
                        class="py-1 text-sm text-gray-700"
                        aria-labelledby="dropdownDefault"
                      >
                        {links.map((i) => (
                          <li key={i.id} className="">
                            <button
                              id={i.id}
                              type="submit"
                              class="hover:bg-gray-200 px-4 py-3 w-full text-left text-sm text-gray-700 border-b flex items-center gap-3"
                              onClick={(e) => onClickAction(e, i)}
                            >
                              <img
                                className="h-5 w-auto"
                                src="pdf.svg"
                                alt="pdf"
                              />
                              {i.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="sm:w-28">
                  <button
                    type="submit"
                    className="inline-flex gap-1 disabled:opacity-60 disabled:cursor-not-allowed h-9 w-full justify-center py-2 px-4 border border-lime-600 bg-lime-600 border-transparent shadow-sm text-sm font-semibold rounded-md text-white hover:bg-lime-900 hover:border-lime-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={OnSubmit}
                    disabled={
                      (job?.originPortCode === null &&
                        job.jobStatus !== "Canceled") ||
                      (job?.destinationPortCode === null&&
                        job.jobStatus !== "Canceled") ||
                      show.loading ||
                      ((job.jobType === 100 || job.jobType === 400) &&
                        job.destinWarehouseId === null)
                    }
                  >
                    {show.loading && (
                      <svg
                        aria-hidden="true"
                        role="status"
                        class="mr-2 inline h-5 w-5 animate-spin text-green-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#FFF"
                        ></path>
                      </svg>
                    )}
                    Save
                  </button>
                </div>

                <div className="sm:w-28">
                  <button
                    type="button"
                    className="inline-flex h-9 float-right w-full justify-center py-2 px-4 border-2 border-black shadow-sm text-sm font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    onClick={onCloseClick}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>

            <TabPanel className="">
              <JobForm
                setJob={setJob}
                job={job}
                OnSubmit={OnSubmit}
                defaultJob={defaultJob}
                setModified={setModified}
                formDisabled={formDisabled}
              />
              {/* )} */}
            </TabPanel>
            <TabPanel className="">
              {job && job.id && (
                <JobPoes
                  job={job}
                  setJob={setJob}
                  formDisabled={formDisabled}
                  setModified={setModified}
                />
              )}
            </TabPanel>
            <TabPanel className="">
              {job && job.id && (
                <DocumentForm
                  documents={job.documents}
                  documentUploaded={onDocumentUploaded}
                  documentDeleted={onDocumentDeleted}
                  formDisabled={formDisabled}
                  setState={setJob}
                />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <div className="w-1/3 h-full bg-zinc-100 relative">
        <div className="h-[62vh]">
          {job && job.id && (
            <Events
              job={job}
              onLoad={onLoad}
              setJob={setJob}
              formDisabled={formDisabled}
            />
          )}
        </div>
        <div className="absolute w-full bottom-0 right-0">
          <PackageDetails
            job={job}
            setJob={setJob}
            formDisabled={formDisabled}
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default JobDetails;
