import * as React from "react";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePicker } from "@progress/kendo-react-dateinputs";
export const TimeCell = (props) => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };
  const { dataItem } = props;
  //   const field = props.field || "";
  const dataValue = dataItem === null ? "" : dataItem?.displayTime;
  return (
    <td>
      {dataItem.inEdit ? (
        <div
          className=" h-9 border rounded-md border-[#DBDBDB]"
          id="eventDateCompo"
        >
          <TimePicker
            onChange={handleChange}
            value={dataItem?.time || null}
            className="w-full h-full rounded-md placeholder:text-sm text-xs text-neutral-400 text-xxs placeholder:text-black"
            placeholder="Select Time"
          />
        </div>
      ) : (
        dataItem?.displayTime || ""
      )}
    </td>
  );
};
