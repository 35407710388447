import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import { formatDate } from "@telerik/kendo-intl";
import { useNavigate } from "react-router-dom";
import { CustomColumnMenu } from "../CollumnMenu";
import { JobLoader } from "./JobLoader";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import KendoLoader from "../KendoLoader";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { debounce } from "lodash";

const FilterContent = ({
  className,
  filterData,
  filterList,
  filteredCustomers,
  filteredVessels,
  showButtonLabel,
  onJobNoChange,
  onAwbChange,
  onVesselChange,
  onCustomerChange,
  onJobFilter,
  onResetClick,
}) => {
  return (
    <div className={className}>
      {/* Input for Job No */}
      <div className="flex justify-between items-center gap-2 text-xs text-gray-900 font-medium">
        <label>Job No</label>
        <input
          type="text"
          name="jobNo"
          className="w-40 h-7 rounded-md shadow-sm  border border-slate-200 text-neutral-400 text-sm font-normal"
          value={filterData?.jobNo}
          onChange={onJobNoChange}
        />
      </div>

      {/* Input for AWB */}
      <div className="flex justify-between items-center gap-2 text-xs text-gray-900 font-medium">
        <label>AWB</label>
        <input
          type="text"
          name="awb"
          className="w-40 h-7 rounded-md shadow-sm  border border-slate-200 text-neutral-400 text-sm font-normal"
          value={filterData?.awb}
          onChange={onAwbChange}
        />
      </div>

      {/* ComboBox for Vessel */}
      <div className="flex justify-between items-center gap-2 text-xs text-gray-900 font-medium">
        <label>Vessel</label>
        <div
          className="w-40 h-7 border-t rounded-lg border-slate-200"
          id="compo"
        >
          {" "}
          <ComboBox
            data={filteredVessels}
            name="vessel"
            dataItemKey="id"
            textField="name"
            className="w-40 h-7 border rounded-md shadow-sm"
            value={filterData?.vessel}
            onFilterChange={filterList}
            clearButton
            filterable
            onChange={onVesselChange}
          />
        </div>
      </div>

      {/* ComboBox for Customer */}
      <div className="flex justify-between items-center gap-2 text-xs text-gray-900 font-medium">
        <label>Customer</label>
        <div
          className="w-40 h-7 border-t rounded-lg border-slate-200"
          id="compo"
        >
          <ComboBox
            data={filteredCustomers}
            name="customer"
            dataItemKey="id"
            textField="name"
            className="w-40 h-7 border rounded-md shadow-sm"
            value={filterData?.customer}
            onFilterChange={filterList}
            clearButton
            filterable
            onChange={onCustomerChange}
          />
        </div>
      </div>

      {/* Buttons for Search and Reset */}
      <div className="flex gap-2">
        <button
          type="button"
          class="h-7 flex justify-center gap-2 items-center bg-sky-500 rounded text-sm font font-medium text-white w-1/2 px-1"
          title="Search"
          onClick={() => onJobFilter(filterData)}
        >
          {showButtonLabel ? "Search" : ""}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            ></path>
          </svg>
        </button>

        <button
          type="button"
          className="h-7 px-2 flex gap-2 items-center justify-center bg-black text-white text-sm font-medium rounded w-1/2"
          onClick={onResetClick}
        >
          {showButtonLabel ? "Reset" : ""}
          <img src="reset-icon.svg" alt="Reset" />
        </button>
      </div>
    </div>
  );
};

const KendoDateCell = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td className=" bg-inherit">
      {formatDate(new Date(value), "dd-MMM-yyyy")}
    </td>
  );
};

const ActiveCell = (props) => {
  const value = props.dataItem.jobStatus;
  return (
    <td {...props}>
      <div
        className={`${
          value === "Active"
            ? "bg-sky-500 border-sky-600"
            : value === "Closed"
            ? "bg-green-500 border-green-600"
            : value === "Canceled"
            ? "bg-red-500 border-red-600"
            : value === "Checked" && "bg-orange-500 border-orange-600"
        } w-16 rounded-xl text-white text-xxs font-semibold h-5 flex justify-center items-center`}
      >
        {value}
      </div>
    </td>
  );
};

function ProductNameCell(props) {
  return (
    <td title={props.dataItem[props.field]}>{props.dataItem[props.field]}</td>
  );
}

const ChevronDownIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

function ProductNameHeader(props) {
  return (
    <a className="k-link" onClick={props.onClick}>
      <span title={props.title}>{props.title}</span>
      {props.children}
    </a>
  );
}

const JobNoCell = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td>
      <div className="flex gap-2">
        <a target="_blank" title="Open New Tab" href={`#/job/${props.dataItem.id}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="blue"
            class="h-3 shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </a>{" "}
        {value}
      </div>
    </td>
  );
};

const columns = [
  {
    field: "jobNo",
    title: "Job No",
    show: true,
    width: 150,
    filterable: true,
    sortable: true,
    locked: true,
    cell: JobNoCell,
  },
  {
    field: "vesselName",
    title: "Vessel",
    show: true,
    width: "200",
    filterable: true,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "customerName",
    title: "Customer",
    show: true,
    width: "200",
    filterable: true,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "consigneeName",
    title: "Consignee",
    show: true,
    width: "200",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "shipperName",
    title: "Shipper",
    show: true,
    width: "200",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "awb",
    title: "AWB/B/L",
    show: true,
    width: 135,
    filterable: false,
    sortable: false,
  },
  // {
  //   field: "destinationWhName",
  //   title: "WH Destination",
  //   show: true,
  //   width: 80,
  //   filterable: false,
  //   sortable: false,
  // },
  {
    field: "originPortName",
    title: "Origin Port",
    show: true,
    width: "180",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "destinationPortName",
    title: "Destination Port",
    show: true,
    width: "180",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "creationDate",
    title: "Creation Date",
    show: true,
    width: "155",
    filterable: false,
    sortable: false,
    cell: KendoDateCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "jobStatus",
    title: "Status",
    show: true,
    width: "110",
    filterable: false,
    sortable: false,
    cell: ActiveCell,
    locked: true,
  },
];

export default function JobList() {
  const defaultFilter = {
    vessel: null,
    customer: null,
    jobNo: "",
    awb: "",
    page: 0,
    pageSize: 11,
    total: 0,
  };
  const defaultState = {
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 11,
  };
  let navigate = useNavigate();
  const _export = React.useRef(null);
  const [jobs, setJobs] = useState([]);
  const [vessels, setVessels] = useState();
  const [filteredVessels, setFilteredVessels] = useState();
  const [customers, setCustomers] = useState();
  const [filteredCustomers, setFilteredCustomers] = useState();
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState(defaultFilter);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageState, setPageState] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 11,
  });

  const [stateColumns, setStateColumns] = React.useState(columns);
  const filterRef = useRef(null);

  // Close dropdown on outside click
  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (filterRef.current && !filterRef.current.contains(event.target)) {
  //       setFilterOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const onEditClick = (e) => {
    const editedItem = e.dataItem;
    navigate(`/job/${editedItem.id}`);
  };

  const handleGridDataStateChange = (e) => {
    setPageState(e.dataState);
  };

  const dataReceived = (result) => {
    setJobs(result.data.data);
    setFilterData((pre) => ({ ...pre, total: result.data.totalCount }));
  };

  const onAwbChange = async (e) => {
    setFilterData({ ...filterData, awb: e.target.value });
  };

  const onJobNoChange = async (e) => {
    setFilterData({ ...filterData, jobNo: e.target.value.toUpperCase() });
  };

  const onCustomerChange = async (e) => {
    setFilterData({
      ...filterData,
      customer: e.value,
      customerId: e.value ? e.value.id : null,
    });
  };

  const onVesselChange = async (e) => {
    setFilterData({
      ...filterData,
      vessel: e.value,
      vesselId: e.value ? e.value.id : null,
    });
  };

  // const filterList = debounce(async (e) => {
  //   let res;
  //   let searchText;
  //   if (e.filter.value === "") searchText = "~ALL";
  //   else searchText = e.filter.value;
  //   switch (e.target.name) {
  //     case "vessel":
  //       res = await axios.get(
  //         `${BASE_URL}/vessel?searchText=${searchText}&page=1&pageSize=20`
  //       );
  //       setVessels(res.data.data);
  //       break;
  //   }
  // }, 300);

  const onJobFilter = async (filter) => {
    setLoading(true);
    setFilterOpen(false);
    let payload = {
      jobNo: filter.jobNo,
      awb: filter.awb,
      page: filter.page,
      pageSize: filter.pageSize,
    };
    if (filter.customerId) {
      payload.customerId = filter.customerId;
    }
    if (filter.vesselId) {
      payload.vesselId = filter.vesselId;
    }
    const res = await axios.post(`${BASE_URL}/Job/GetJobFilter`, payload);
    if (res.status === 200) {
      if (res.data.legth !== 0) {
        dataReceived(res);
      } else {
        toast.error("No Data Found");
      }
    } else {
      toast.error("Error occured while filtering Data");
    }
    setLoading(false);
  };

  const onResetClick = () => {
    setFilterData(defaultFilter);
    setFilterOpen(false);
    onJobFilter(defaultFilter);
  };

  const loadData = async () => {
    let res = await axios.get(
      `${BASE_URL}/vessel?searchText=~ALL&page=1&pageSize=20`
    );
    setVessels(res.data.data);
    setFilteredVessels(res.data.data);
    res = await axios.get(
      `${BASE_URL}/Lookup/LimitedCustomers?searchText=~ALL`
    );
    setCustomers(res.data);
    setFilteredCustomers(res.data);
  };

  const onPageChange = (e) => {
    const state = { ...pageState, skip: e.page.skip, take: e.page.take };
    setPageState(state);
    setFilterData({ ...filterData, page: state.skip, pageSize: state.take });
  };

  const filterList = debounce(async (e) => {
    let res;
    let searchText;
    if (e.filter.value === "") searchText = "";
    else searchText = e.filter.value.toLowerCase().replace(/ /g, "");
    switch (e.target.name) {
      case "customer":
        if (searchText !== "") {
          res = await axios.get(
            `${BASE_URL}/Lookup/LimitedCustomers?searchText=${searchText.toUpperCase()}`
          );
          setFilteredCustomers(res.data);
        } else {
          res = await axios.get(
            `${BASE_URL}/Lookup/LimitedCustomers?searchText=~ALL`
          );
          setFilteredCustomers(res.data);
        }
        break;
      case "vessel":
        if (searchText !== "") {
          res = await axios.get(
            `${BASE_URL}/Lookup/Vessels?searchText=${searchText.toUpperCase()}&page=1&pageSize=20`
          );
          setFilteredVessels(res.data);
        } else {
          res = await axios.get(
            `${BASE_URL}/Lookup/Vessels?searchText=~ALL&page=1&pageSize=20`
          );
          setFilteredVessels(res.data);
        }
        break;
      default:
        break;
    }
  }, 300);

  useEffect(() => {
    loadData();
  }, []);

  // Count non-empty relevant filter fields
  const getFilterCount = () => {
    const relevantFilterKeys = ["jobNo", "awb", "vesselId", "customerId"];
    return relevantFilterKeys.filter((key) => {
      const value = filterData[key];
      // Check if value exists, is a string, and is not empty after trimming
      return (
        value !== undefined && value !== null && String(value).trim() !== ""
      );
    }).length;
  };

  return (
    <div className="mx-4 my-4 sm:mx-6 lg:mx-8">
      <div className="flex justify-between mb-4">
        <div className="flex justify-between w-full min-w-0">
          <div className="flex gap-1">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              Jobs
            </h1>
            <div>
              <div className="dropdown inline-block relative">
                <div className="flex justify-center gap-2 items-center w-14 h-6 text-center p-1 bg-zinc-300 rounded-xl text-sm font-medium ml-2">
                  <div className="text-xs text-zinc-500">All</div>
                  <span className="mr-1 text-xs">{filterData.total}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            {/* Mobile Filters */}
            <div className="2xl:hidden flex relative" ref={filterRef}>
              <button
                onClick={() => setFilterOpen(!filterOpen)}
                className="flex items-center h-7 gap-2 px-4 text-sm font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  className="w-4 h-4"
                >
                  <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                </svg>
                Filters
                {getFilterCount() > 0 && (
                  <span className="ml-2 bg-sky-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xxs">
                    {getFilterCount()}
                  </span>
                )}
                <ChevronDownIcon
                  className={`w-4 h-4 transition-transform ${
                    filterOpen ? "rotate-180" : ""
                  }`}
                />
              </button>
              {filterOpen && (
                <div className="absolute top-3 mt-5 z-70 w-max bg-gray-100 rounded-lg shadow-md border p-2">
                  <FilterContent
                    className={"p-2 space-y-4"}
                    showButtonLabel={true}
                    filterData={filterData}
                    onJobNoChange={onJobNoChange}
                    onAwbChange={onAwbChange}
                    onVesselChange={onVesselChange}
                    onCustomerChange={onCustomerChange}
                    onJobFilter={onJobFilter}
                    onResetClick={onResetClick}
                    filterList={filterList}
                    filteredCustomers={filteredCustomers}
                    filteredVessels={filteredVessels}
                  />
                </div>
              )}
            </div>

            {/* Desktop Filters */}
            <div className="2xl:flex hidden gap-2">
              <FilterContent
                className={"flex gap-2"}
                filterData={filterData}
                onJobNoChange={onJobNoChange}
                onAwbChange={onAwbChange}
                onVesselChange={onVesselChange}
                onCustomerChange={onCustomerChange}
                onJobFilter={onJobFilter}
                onResetClick={onResetClick}
                filterList={filterList}
                filteredCustomers={filteredCustomers}
                filteredVessels={filteredVessels}
              />
            </div>

            {/* Add New Job Button */}
            <button
              type="button"
              className="inline-flex h-7 w-fit px-2 items-center gap-1 text-xs font-semibold rounded text-white bg-lime-600 shadow-sm"
              onClick={() => navigate(`/job/new`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Add New O-WH Job
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="-my-2 overflow-x-autow-full">
          <div className="py-2 align-middle inline-block w-full min-w-full">
            <div className="shadow job relative w-full overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {loading && <KendoLoader />}
              <ExcelExport data={jobs} ref={_export}>
                <Tooltip openDelay={300} position="top" anchorElement="target">
                  <Grid
                    data={process(jobs, defaultState)}
                    pageable={{
                      pageSizes: true,
                    }}
                    // filterable={true}
                    selectable={{
                      enabled: true,
                      drag: false,
                      cell: false,
                      mode: "multiple",
                    }}
                    reorderable={true}
                    //total={gri}
                    onDataStateChange={handleGridDataStateChange}
                    onRowDoubleClick={onEditClick}
                    onPageChange={onPageChange}
                    {...pageState}
                    total={filterData.total}
                    style={{
                      borderRadius: "10px",
                      borderColor: "#E5E7EB",
                      fontSize: "12px",
                      fontFamily: "poppins",
                      zIndex: 0,
                      height: "78vh",
                      width: "100%",
                    }}
                  >
                    {stateColumns.map(
                      (column, idx) =>
                        column.show && (
                          <GridColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                            filterable={column.filterable}
                            cell={column.cell}
                            selectable={true}
                            width={column.width}
                            headerCell={column.ProductNameHeader}
                            locked={column?.locked}
                            // columnMenu={(props) => (
                            //   <CustomColumnMenu
                            //     {...props}
                            //     columns={stateColumns}
                            //     onColumnsSubmit={onColumnsSubmit}
                            //   />
                            // )}
                          />
                        )
                    )}
                  </Grid>
                </Tooltip>
                <JobLoader
                  dataState={pageState}
                  onDataReceived={dataReceived}
                  filterData={filterData}
                />
              </ExcelExport>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
