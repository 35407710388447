import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../constants";
import SlidingPane from "react-sliding-pane";

export default function VesselSlider({
  open,
  setOpen,
  selectedMaster,
  onUpdate,
  onCreate,
  setSelectedMaster,
}) {
  const [countries, setCountries] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${BASE_URL}/Lookup/Countries?searchText=~ALL`).then((res) => {
      setCountries(res.data);
    });
    axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`).then((res) => {
      setCustomers(res.data);
    });
  }, []);
  const formik = useFormik({
    initialValues: selectedMaster,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const selectedCountry = countries.find(
        (x) => x.code === values.flagCountryCode
      );
      if (selectedCountry) {
        values.flagCountryName = selectedCountry.name;
      }
      if (selectedMaster.id) {
        axios
          .put(`${BASE_URL}/vessel`, values)
          .then((res) => {
            onUpdate(values);
            setLoading(false);
            toast.success("Vessel Details Updated Successfully");
            //setOpen(false);
          })
          .catch((error) => {
            toast.error("An error occured while saving the vessel!");
            setLoading(false);
          });
      } else {
        axios
          .post(`${BASE_URL}/vessel`, values)
          .then((res) => {
            setLoading(false);
            toast.success("Vessel Created Successfully");
            onCreate(values, res.data);
            setSelectedMaster({ ...values, id: res.data });
            //resetForm({ values: "" });
            //setOpen(false);
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              const errorResponse = err.response.data;
              if (errorResponse.errors) {
                const errorMessages = Object.values(errorResponse.errors)
                  .flat()
                  .join(", ");
                toast.error(errorMessages);
              } else {
                toast.error(
                  errorResponse.title || "Validation error occurred."
                );
              }
            } else {
              toast.error("An error occurred while saving the vessel!");
            }

            setLoading(false);
          });
      }
    },
  });
  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={open}
      from="right"
      width="680px"
      className=""
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-screen max-w-2xl h-full">
          <form
            className="relative h-full flex flex-col bg-white shadow-xl"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex-1 h-[70vh] overflow-y-scroll">
              {/* Header */}
              <div className="px-4 py-4 bg-[#e9f4ff] sm:px-6 sticky top-0">
                <div className="flex items-start justify-between space-x-3">
                  <div className="space-y-1 text-2xl">
                    {selectedMaster.id ? "Edit Vessel" : "New Vessel"}
                  </div>
                  <div className="h-7 flex items-center">
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Divider container */}
              <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Name
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="imoNumber"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      IMO
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="imoNumber"
                      id="imoNumber"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.imoNumber}
                    />
                  </div>
                </div>
                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <label
                    htmlFor="customerId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Customer
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      id="customerId"
                      name="customerId"
                      className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.customerID}
                    >
                      <option selected value={null}>
                        Select a customer
                      </option>
                      {customers.map((customer) => {
                        return (
                          <option
                            key={customer.id}
                            value={customer.id}
                            label={customer.name}
                          />
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <label
                    htmlFor="flagCountryCode"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Flag
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      id="flagCountryCode"
                      name="flagCountryCode"
                      className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.flagCountryCode}
                    >
                      {countries.map((c) => {
                        return (
                          <option key={c.code} value={c.code} label={c.name} />
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="yearBuilt"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Year Built
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="number"
                      name="yearBuilt"
                      id="yearBuilt"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.yearBuilt}
                    />
                  </div>
                </div>

                <fieldset className="pb-4">
                  <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                    <div>
                      <legend className="text-sm font-medium text-gray-900">
                        Active
                      </legend>
                    </div>
                    <div className="space-y-5 sm:col-span-2">
                      <div className="space-y-5 sm:mt-0">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="active"
                              name="active"
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              onChange={formik.handleChange}
                              defaultChecked={formik.values.active}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            {/* Action buttons */}
            <div className=" w-full px-4 border-t bg-white border-gray-200 py-4 sm:px-6">
              <div className="space-x-3 flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  disabled={
                    !(formik.isValid && formik.dirty) ||
                    formik.values.name === "" ||
                    formik.values.imoNumber === ""
                  }
                >
                  {loading && (
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {selectedMaster.id ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
        </div>
      </div>
    </SlidingPane>
  );
}
