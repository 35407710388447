import * as React from "react";
import * as ReactDOM from "react-dom";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { BASE_URL } from "../../constants";
import axios from "axios";

export const JobLoader = ({ dataState, onDataReceived, filterData }) => {
  const baseUrl = `${BASE_URL}/Job/GetJobFilter`;
  const init = {
    method: "GET",
    accept: "application/json",
    headers: {},
  };
  const lastSuccess = React.useRef("");
  const pending = React.useRef("");
  const requestDataIfNeeded = () => {
    if (
      pending.current ||
      toDataSourceRequestString(dataState) === lastSuccess.current
    ) {
      return;
    }
    pending.current = toDataSourceRequestString(dataState);

    // fetch(baseUrl + pending.current, init)
    axios
      .post(baseUrl, filterData)
      // .then((response) => response.json())
      .then((resp) => {
        lastSuccess.current = pending.current;
        pending.current = "";
        if (toDataSourceRequestString(dataState) === lastSuccess.current) {
          onDataReceived.call(undefined, resp);
        } else {
          requestDataIfNeeded();
        }
      });
  };

  React.useEffect(() => {
    requestDataIfNeeded();
  }, [dataState]);

  return pending.current ? <LoadingPanel /> : null;
};
const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};
