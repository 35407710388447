import * as React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridColumn,
} from "@progress/kendo-react-grid";
// @ts-expect-error
// @ts-expect-error
// import { insertItem, getItems, updateItem, deleteItem } from './shared-gd-services';
import { Button } from "@progress/kendo-react-buttons";
import { CommandCell } from "./CommandCell";
import { ActiveCell } from "./ActiveCell";
import { TimeCell } from "./TimeCell";
import { DropDownCell } from "./DropDownCell";
import KendoLoader from "../KendoLoader";
const editField = "inEdit";
// @ts-expect-error

const MyCommandCell = (props) => {
  const { edit, remove, add, discard, update, cancel, editField } = props;
  return (
    <CommandCell
      {...props}
      edit={edit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );
};
const EditableGrid = ({
  data,
  setData,
  customers,
  formatTime,
  editingForm,
  setEditingForm,
  loading,
  updateCustomersDropdown,
}) => {
  // modify the data in the store, db etc
  const remove = (dataItem) => {
    setData((pre) => {
      const modifiedData = pre.filter((x) => x.id !== dataItem.id);
      const customerIds = modifiedData.map((x) => x.customerId);
      updateCustomersDropdown(customerIds);
      return modifiedData;
    });
  };

  //   const add = (dataItem) => {
  //     dataItem.inEdit = true;
  //     const newData = [...data];
  //     setData(newData);
  //   };

  const update = (dataItem) => {
    dataItem.inEdit = false;
    setData((pre) =>
      pre.map((x) => (x.id === dataItem.id ? { ...dataItem } : x))
    );
  };

  // Local state operations
  const discard = () => {
    if (editingForm.isNew) remove(editingForm);
    else
      setData((pre) => {
        const modifiedData = pre.map((x) =>
          x.id === editingForm.id ? { ...editingForm } : x
        );
        const customerIds = modifiedData.map((x) => x.customerId);
        updateCustomersDropdown(customerIds);
        return modifiedData;
      });
  };

  //   const cancel = (dataItem) => {
  //     const newData = data;
  //     setData(newData);
  //   };

  const enterEdit = (dataItem) => {
    setEditingForm({ ...dataItem, isNew: false });
    setData((pre) =>
      pre.map((item) =>
        item.id === dataItem.id
          ? {
              ...item,
              inEdit: true,
            }
          : item
      )
    );
  };

  const itemChange = (event) => {
    const newData = data.map((item) =>
      item.id === event.dataItem.id
        ? event.field === "time"
          ? {
              ...item,
              time: event.value,
              displayTime: formatTime(event.value, "display"),
            }
          : event.field === "customerId"
          ? {
              ...item,
              customerId: event.value?.id,
              customerName: event.value?.name,
            }
          : {
              ...item,
              [event.field]: event.value,
            }
        : item
    );
    const customerIds = newData.map((x) => x.customerId);
    updateCustomersDropdown(customerIds);
    setData(newData);
  };

  const validate = (dataItem) => {
    if (dataItem.customerId === null || dataItem.time === null) {
      return true;
    } else return false;
  };

  const commandCellProps = {
    edit: enterEdit,
    remove: remove,
    // add: add,
    discard: discard,
    update: update,
    // cancel: cancel,
    validate: validate,
    editField: editField,
  };

  return (
    <div>
      {loading && <KendoLoader />}
      <Grid
        style={{ height: "300px" }}
        data={data}
        onItemChange={itemChange}
        editField={editField}
      >
        <GridColumn
          field="customerId"
          title="Customer"
          width="300"
          cell={(props) => <DropDownCell {...props} customers={customers} />}
        />
        <GridColumn field="time" title="Time" width="200px" cell={TimeCell} />
        <GridColumn
          field="lastRefreshed"
          title="Last Refreshed"
          editable={false}
          width="200px"
        />
        <GridColumn
          field="active"
          title="Active"
          editable={false}
          cell={ActiveCell}
          width="150px"
        />
        <Column
          title="Action"
          cell={(props) => <CommandCell {...props} {...commandCellProps} />}
          width="200px"
        />
      </Grid>
    </div>
  );
};
export default EditableGrid;
