import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Package } from "./Package";

export const PackageList = ({ po, setPo, packageAdded, id }) => {
  const navigate = useNavigate();
  const defaultPackage = {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    volume: 0,
    chWeight: 0,
    colli: 1,
    editMode: false,
  };

  const addNewBox = () => {
    setPo({
      ...po,
      packages: [
        ...po.packages,
        { ...defaultPackage, editMode: true, id: po.packages.length + 1,isNew:true },
      ],
    });
  };

  const onPackageSaved = (pp) => {
    setPo({
      ...po,
      packages: po.packages.map((cp) =>
        cp.id === pp.id ? { ...pp, editMode: false } : cp
      ),
    });
  };
  const onPackageDeleted = (pp, e) => {
    setPo({
      ...po,
      packages: po.packages.filter((item) => item.id !== pp.id),
    });
  };

  return (
    <div className="">
      <div className="flex items-center mb-4 justify-between w-full">
        <p className="font-medium text-lg text-black">Package Details</p>
        {po.packages.length > 0 && (
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            class=" bg-sky-600 py-2 px-4 ml-2 rounded-lg shadow hover:shadow-xs duration-300 w-24 h-10 text text-white font-semibold text-sm"
            type="button"
            onClick={() => {
              navigate(`/printdocPo/9/${id}`);
            }}
          >
            Print
          </button>
        )}
        {po.packages.length === 0 && (
          <button
            type="button"
            className="rounded-md px-2 py-2 text-xs font-semibold uppercase border-2 text-black border-black ml-auto hover:bg-black hover:text-white"
            onClick={addNewBox}
          >
            New Box
          </button>
        )}
      </div>
      <div className="overflow-y-auto sm:h-[450px] pr-2">
        <div className="">
          <ul>
            {po.packages?.map((p, i) => {
              return (
                <Package
                  key={i}
                  pack={p}
                  packageSaved={onPackageSaved}
                  packageDeleted={onPackageDeleted}
                  index={i}
                ></Package>
              );
            })}
          </ul>
        </div>
        {po.packages.length > 0 && (
          <div className="flex items-center mt-5 mb-3">
            <button
              type="button"
              className="rounded-md px-2 py-2 font-semibold text-xs uppercase border-2 text-black border-black ml-auto hover:bg-black hover:text-white"
              onClick={addNewBox}
            >
              New Box
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
