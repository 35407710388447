import AppContext from "./context/app-context";
import { useContext } from "react";
import LogIn from "./components/LogIn";
import Layout from "./components/Layout";
import CustomerMaster from "./components/masters/customer/CustomerMaster";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import PoList from "./components/po/PoList";
import PoDetails from "./components/po/PoDetails";
import JobList from "./components/job/JobList";
import "react-toastify/dist/ReactToastify.css";
import SupplierMaster from "./components/masters/supplier/SupplierMaster";
import AgentMaster from "./components/masters/agent/AgentMaster";
import VesselMaster from "./components/masters/vessel/VesselMaster";
import WarehouseMaster from "./components/masters/warehouse/WarehouseMaster";
import PortMaster from "./components/masters/port/PortMaster";
import StockReport from "./components/reports/StockReport";
import UserMaster from "./components/masters/user/UserMaster";
import JobDetails from "./components/job/JobDetails";
import PasswordReset from "./components/PasswordReset";
import ChangePassword from "./components/ChangePassword";
import PrintDoc from "./components/job/PrintDoc";
import PartyMaster from "./components/masters/party/PartyMaster";
import CityMaster from "./components/masters/city/CityMaster";
import axios from "axios";
import Home from "./components/home/Home";
import QuotationList from "./components/quotation/QuotationList";
import CreateQuotation from "./components/quotation/CreateQuotation";
import ServiceMaster from "./components/masters/service/ServiceMaster";
import ServiceTemplateMaster from "./components/masters/serviceTemplate/ServiceTemplateMaster";
import PlannerMaster from "./components/planner/PlannerMaster";
import CreatePlanner from "./components/planner/CreatePlanner";

function App() {
  const authCtx = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;
  return (
    <>
      {isLoggedIn ? (
        <HashRouter>
          <Layout>
            <Routes>
              <Route
                path="/"
                element={authCtx.profile.role === 300 ? <PoList /> : <Home />}
              />
              <Route path="home" element={<Home />} />
              <Route path="planner" element={<PlannerMaster />} />
              <Route path="planner/new" element={<CreatePlanner />} />
              <Route path="poes/:filterValue" element={<PoList />} />
              <Route path="quotations" element={<QuotationList />} />
              <Route path="quotation/:id" element={<CreateQuotation />} />
              <Route path="/po/:id" element={<PoDetails />} />
              <Route path="jobs" element={<JobList />} />
              <Route path="customer" element={<CustomerMaster />} />
              <Route path="supplier" element={<SupplierMaster />} />
              <Route path="vessel" element={<VesselMaster />} />
              <Route path="agent" element={<AgentMaster />} />
              <Route path="warehouse" element={<WarehouseMaster />} />
              <Route path="port" element={<PortMaster />} />
              <Route path="stock" element={<StockReport />} />
              <Route path="user" element={<UserMaster />} />
              <Route path="party" element={<PartyMaster />} />
              <Route path="city" element={<CityMaster />} />
              <Route path="charge" element={<ServiceMaster />} />
              <Route
                path="tariff"
                element={<ServiceTemplateMaster />}
              />
              <Route path="job/:id" element={<JobDetails />} />
              <Route path="printdocJob/:docid/:jobId" element={<PrintDoc />} />
              <Route path="printdocPo/:docid/:poId" element={<PrintDoc />} />
            </Routes>
          </Layout>
        </HashRouter>
      ) : (
        <HashRouter>
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="resetPassword" element={<PasswordReset />} />
            <Route path="changePassword/:token" element={<ChangePassword />} />
          </Routes>
        </HashRouter>
      )}
    </>
  );
}

export default App;
