import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import { BASE_URL } from "../../constants";
import axios from "axios";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { TextLoader } from "./TextLoader";
import ListLoader from "./ListLoader";
import GraphLoader from "./GraphLoader";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const circumference = 23 * 2 * Math.PI;
  const topActivities = [
    { id: 1, date: "20-10-2021", status: "In Progress", progress: 50 },
    { id: 2, date: "20-10-2021", status: "In Progress", progress: 60 },
    { id: 3, date: "20-10-2021", status: "In Progress", progress: 70 },
    { id: 4, date: "20-10-2021", status: "In Progress", progress: 30 },
  ];
  const [dashboardDetails, setDashboardDetails] = useState();

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const loadData = async () => {
    const res = await axios.get(
      `${BASE_URL}/DashboardDetails/GetDashboardDeatils`
    );
    if (res.status === 200) {
      setDashboardDetails({
        ...res.data,
        customerPoCount: res.data.customerPoCount.map((wpc) => ({
          ...wpc,
          color: getRandomColor(),
        })),
        warehousePoCount: res.data.warehousePoCount.map((wpc) => ({
          ...wpc,
          color: getRandomColor(),
        })),
        locationPoCount: res.data.locationPoCount.map((vpc) => ({
          ...vpc,
          color: getRandomColor(),
        })),
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const statusData = [
    { title: "In Transit", value: "poInTransit" },
    { title: "In Stock", value: "poInStock" },
    { title: "Delivered", value: "poDelivered" },
    { title: "Agent", value: "poNotApproved", link: true },
  ];

  return (
    <div className="px-7 py-4">
      <div className="flex justify-between pb-4">
        <div className="flex flex-col">
          <div className="text-xl font-medium leading-8">
            Hello , {authCtx.profile.displayname}
          </div>
          <div className="text-xs text-stone-300 -mt-1">Welcome back</div>
        </div>
      </div>
      <div className="h-[75Vh] lg:flex-row flex flex-col gap-4">
        <div className="lg:w-1/2 w-full flex flex-col gap-4 h-full">
          <div className="flex flex-col justify-between bg-white px-10 pt-4 2xl:pb-9 pb-5 rounded-2xl relative h-1/2">
            <div className="flex">
              <div className="flex flex-col gap-2">
                <div className="2xl:text-xl text-lg font-medium">
                  Total Purchase Orders
                </div>
                {/* <div className="text-stone-300 text-xs font-medium">Details</div> */}
                <div className="flex gap-4 2xl:text-5xl text-4xl font-medium">
                  {dashboardDetails ? (
                    dashboardDetails.poCount
                  ) : (
                    <div className="flex gap-2 animate-pulse h-8 w-16 items-end">
                      <TextLoader />
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <div className="absolute -top-7 right-10 z-30">
                  <img
                    src="login-img.svg"
                    alt="login-img"
                    className="2xl:h-auto h-32"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2 w-full mt-2 2xl:mt-0">
              {statusData.map((x) => (
                <div
                  className={`flex flex-col justify-center items-center gap-2 w-1/5 2xl:py-3 py-2 rounded-xl ${
                    x.link
                      ? "bg-orange-400 bg-opacity-30 hover:underline hover:text-sky-500"
                      : "bg-slate-100"
                  }`}
                  onClick={
                    x.link ? () => navigate("/poes/notApproved") : undefined
                  }
                >
                  <label className="font-medium 2xl:text-sm text-xs">
                    {x.title}
                  </label>
                  <label className="font-medium 2xl:text-base text-sm">
                    {dashboardDetails ? (
                      dashboardDetails[x.value]
                    ) : (
                      <div className="flex gap-2 animate-pulse h-5 w-16 items-end">
                        <TextLoader />
                      </div>
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex h-1/2 gap-4">
            <div className="w-1/2 flex flex-col bg-white py-4 rounded-2xl h-full">
              <div className="flex justify-between px-5 bg-white">
                <div className=" text-sm font-semibold">Location</div>
                <div className="flex text-xs font-semibold items-center justify-center bg-gray-300 w-10 rounded-xl">
                  {dashboardDetails?.locationPoCount.length}
                </div>
              </div>
              <div className="flex flex-col overflow-y-scroll px-5 py-4 gap-5">
                {dashboardDetails ? (
                  dashboardDetails.locationPoCount.map((c) => (
                    <div
                      className=" justify-between pl-3 items-center gap-2 h-1/3 flex"
                      style={{ borderLeft: "4px solid " + c.color }}
                      key={c.propertyId}
                    >
                      <div className="2xl:text-xs text-xxs font-medium">
                        {c.propertyName}
                      </div>
                      <hr className="w-full" />
                      <div className="font-medium 2xl:text-md text-base text-blue-500">
                        {c.poCount}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="h-full">
                    <ListLoader />
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2 flex flex-col bg-white py-4 rounded-2xl h-full">
              <div className="flex justify-between px-5">
                <div className=" text-sm font-semibold">Customers</div>
                <div className="flex text-xs font-semibold items-center justify-center bg-gray-300 w-10 rounded-xl">
                  {dashboardDetails?.customerPoCount.length}
                </div>
              </div>
              <div className="flex flex-col overflow-y-scroll px-5 py-4 gap-5">
                {dashboardDetails ? (
                  dashboardDetails.customerPoCount.map((c) => (
                    <div
                      className=" justify-between pl-3 items-center gap-2 h-1/3 flex"
                      style={{ borderLeft: "4px solid " + c.color }}
                      key={c.propertyId}
                    >
                      <div className="2xl:text-xs text-xxs font-medium">
                        {c.propertyName}
                      </div>
                      <hr className="w-1/3" />
                      <div className="font-medium 2xl:text-md text-base text-blue-500">
                        {c.poCount}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <ListLoader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex flex-col gap-y-2">
          <div className="h-2/5 bg-white rounded-2xl px-9 py-4 flex flex-col gap-1 justify-between">
            <div className="2xl:text-xl text-lg font-medium">Total Jobs</div>
            <div className="2xl:text-5xl text-4xl font-medium">
              {dashboardDetails ? (
                dashboardDetails.jobCount
              ) : (
                <div className="flex gap-2 animate-pulse h-5 w-16 items-end">
                  <TextLoader />
                </div>
              )}
            </div>
            <div className="flex gap-2">
              {" "}
              {[
                { label: "Sea", value: "jobSeaMode" },
                { label: "Air", value: "jobAirMode" },
                { label: "Road", value: "jobRoadMode" },
                { label: "Courier", value: "jobCourierMode" },
                { label: "Others", value: "jobOthersMode" },
              ].map((item) => (
                <div
                  key={item.label}
                  className="flex flex-col justify-center items-center gap-2 w-[calc(50%-0.5rem)] md:w-1/5 2xl:py-3 py-2 bg-slate-100 rounded-xl"
                >
                  <label className="font-medium 2xl:text-sm text-xs">
                    {item.label}
                  </label>
                  <label className="font-medium 2xl:text-base text-sm">
                    {dashboardDetails ? (
                      dashboardDetails[item.value]
                    ) : (
                      <div className="flex gap-2 animate-pulse h-5 w-16 items-end">
                        <TextLoader />
                      </div>
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="h-3/5 w-full flex flex-col bg-white rounded-2xl px-9 py-2">
            <div className="font-semibold text-base">Stock by Warehouse</div>
            {dashboardDetails ? (
              <Chart>
                <ChartSeries>
                  <ChartSeriesItem
                    type="column"
                    data={dashboardDetails.warehousePoCount}
                    field="poCount"
                    categoryField="propertyName"
                    key="propertyId"
                    noteTextField="propertyId"
                    notes={false}
                  >
                    <ChartSeriesLabels
                      visible={true}
                      padding={0}
                      color="balck"
                      font="bold 13px Poppins"
                    />
                  </ChartSeriesItem>
                </ChartSeries>
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{
                      visible: false,
                    }}
                  />
                </ChartCategoryAxis>
                <ChartTooltip
                  render={({ point }) => (
                    <div style={{ whiteSpace: "nowrap" }}>{point.category}</div>
                  )}
                />
              </Chart>
            ) : (
              <GraphLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
