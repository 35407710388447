import React, { useEffect, useRef } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const QuotationDocument = ({ closeModal, quotation }) => {
  const componentRef = useRef();
  const contentRef = useRef();

  const printContent = (e) => {
    contentRef.current &&
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    // section.scrollIntoView({ behavior: "smooth", top: offsetTop });
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <div className="h-[90vh] flex py-1" ref={contentRef}>
      <div
        style={{ width: "730px", height: "1060px" }}
        className="bg-neutral-100 noBorder overflow-y-auto overflow-x-hidden space-b-5 rounded h-full w-full border border-neutral-700"
        ref={componentRef}
      >
        {/* Header */}
        <div
          style={{
            background: "#2463EBCC",
          }}
          className="relative w-full text-white flex justify-between items-center rounded-t-lg"
        >
          <img src="vml_bg.svg" className="w-full" />
          <div className="absolute left-0 pl-10">
            <h1 className="text-sm font-medium">
              To&nbsp;:&nbsp;{quotation?.customerName}
            </h1>
            <p className="text-xxs font-medium whitespace-pre-line mt-1">
              {quotation?.customerAddress}
            </p>
          </div>
          <div className="text-right absolute right-0 pr-10">
            <img src="vml_white_logo.svg" className="h-20" />
            <p className="text-xxxs font-medium text-left pr-5">
              Suite 630 2180 Evelyne <br /> Squares, Port Angeloport, OK
              <br />
              16889-2267{" "}
            </p>
          </div>
        </div>

        {/* Quotation Details */}
        <div className="mt-4 bg-white mx-5 rounded px-8 py-3">
          <h2 className="text-xxs flex flex-col font-medium">
            Quotation Number
            <span className="text-sm font-semibold">
              . {quotation?.quotationNo}
            </span>
          </h2>
          <p className="mt-4 text-xs font-medium">{quotation?.comments}</p>
          {/* <p className="mt-6 text-xs font-medium">Dear Miss Scarlet Evans,</p>
          <p className="mt-3 text-xs font-medium">
            Thank you for your enquiry for the shipment of your goods to
            Auckland. We pleased to confirm the rates below for the items listed
            here.
          </p> */}
        </div>

        {/* Customer Details */}
        <div className="mt-2 bg-white mx-5 rounded px-8 py-3 flex gap-8 text-xs">
          <div className="space-y-1">
            <p className="text-xxxs text-neutral-400 font-medium">To</p>
            <p className="font-semibold text-xxs">{quotation?.customerName}</p>
          </div>
          {/* <div className="space-y-1">
            <p className="text-xxxs text-neutral-400 font-medium">Atn</p>
            <p className="font-semibold text-xxs">Some Texts</p>
          </div> */}
          <div className="space-y-1">
            <p className="text-xxxs text-neutral-400 font-medium">Email</p>
            <p className="font-semibold text-xxs">
              {quotation?.customerEmail || "_ _"}
            </p>
          </div>
          <div className="space-y-1">
            <p className="text-xxxs text-neutral-400 font-medium">
              Validity Date
            </p>
            <p className="font-semibold text-xxs">30/09/2024</p>
          </div>
          <div className="space-y-1">
            <p className="text-xxxs text-neutral-400 font-medium">
              Contact Number
            </p>
            <p className="font-semibold text-xxs">
              {quotation?.customerPhone || "_ _"}
            </p>
          </div>
          {/* <div className="space-y-1">
            <p className="text-xxxs text-neutral-400 font-medium">QUOTE REF</p>
            <p className="font-semibold text-xxs">123222</p>
          </div> */}
        </div>

        {/* Shipment Details */}
        <div className="flex w-full gap-3 mx-5 mt-3">
          <div className="w-5/12 px-8 py-3 bg-white rounded">
            <h3 className="font-semibold text-xs w-full">Shipment Details</h3>
            <div className="flex items-start mt-5 w-full">
              <div className="h-4 w-4 bg-neutral-300 rounded-full"></div>
              <hr className="w-4/5 bg-neutral-300 rounded-md h-[2px] my-auto" />
              <div className="h-4 w-4 bg-neutral-300 rounded-full"></div>
            </div>
            <div className="flex items-center w-full">
              <div className=" gap-4 mt-2">
                <p className="text-xxxs text-neutral-400 font-medium">From</p>
                <p className="text-xxs font-medium">
                  {quotation?.originPortName}
                </p>
              </div>
              <div className="flex flex-col w-full items-end">
                <div className="">
                  <p className="text-xxxs text-neutral-400 font-medium text-left">
                    To
                  </p>
                  <p className="text-xxs font-medium">
                    {quotation?.destinationPortName}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-8 py-3 rounded w-1/2">
            <div className="grid grid-cols-3">
              {/* <div>
                <p className="text-xxxs text-neutral-400 font-medium">
                  Description
                </p>
                <p className="text-xxxs font-medium">6 Cuft Carton</p>
              </div> */}
              <div>
                <p className="text-xxxs text-neutral-400 font-medium">
                  Quantity
                </p>
                <p className="text-xxxs font-medium"> 1</p>
              </div>
              <div>
                <p className="text-xxxs text-neutral-400 font-medium">
                  Gross Volume
                </p>
                <p className="text-xxxs font-medium">
                  {quotation?.cargoDetails?.grossVolume} CuFt
                </p>{" "}
              </div>
              <div>
                <p className="text-xxxs text-neutral-400 font-medium">
                  Gross Weight
                </p>
                <p className="text-xxxs font-medium">
                  {quotation?.cargoDetails?.grossWeight}
                </p>
              </div>
            </div>
            <div className="mt-5">
              <p className="text-xxs font-semibold">
                Total Items: <span className="text-xs mr-8">1</span>
                {/* Total
                Volume:
                <span className="text-xs">
                  {quotation?.cargoDetails?.cargoes?.length || 0} Cuft
                </span> */}
              </p>
            </div>
          </div>
        </div>

        {/* Quotation Options */}
        <div className="mx-5 mt-3 pb-5 break-inside-avoid">
          {quotation.shipment.map((x) =>
            x.options.map((y, index) => (
              <div
                key={1}
                className={` bg-white rounded-md break-inside-avoid-page mt-3`}
              >
                <div className="flex rounded-md">
                  <div
                    style={{
                      background:
                        "linear-gradient(133.88deg, #97C9EC -7.66%, #1246FF 105.75%)",
                    }}
                    className="relative flex justify-center items-center bg-blue-700 w-60 rounded-tl-md rounded-bl-md"
                  >
                    <div className="bg-white rounded-full p-1 absolute top-2 right-2">
                      <img
                        src={quotation.modes.find((y) => y.id === x.mode)?.icon}
                        alt="Transport Mode Icon"
                        className="w-5 h-5  "
                      />
                    </div>{" "}
                    <span className="bg-yellow-300 absolute -left-2 top-4 text-xxs w-20 font-semibold px-2 py-1">
                      Option {index + 1}
                    </span>
                    <div className="absolute -left-2 top-10 w-0 h-0 border-l-8 border-t-8 border-r-0 border-solid border-l-transparent border-t-yellow-600"></div>
                    <span className="bg-yellow-300 absolute -left-2 top-4 text-xxs font-semibold px-2 py-1"></span>
                    <div className="absolute left-[65px] border-transparent top-5 w-0 h-0 border-l-[16px] border-t-[16px] rotate-45 border-r-0 border-solid border-l-transparent border-l-blue-400"></div>
                    <h3 className=" text-white text-lg mt-2">
                      {quotation.modes.find((y) => y.id === x.mode)?.mode}{" "}
                      Transport
                    </h3>
                  </div>
                  <div className="mx-5 my-2 w-full">
                    {/* <div className="text-xs font-semibold mt-1">
                      Airfreight Door to Airport ( Uncleared ) Quotation
                    </div> */}
                    <div className="grid grid-cols-2 gap-4 mt-3 pl-4">
                      <div>
                        <p className="text-gray-400 text-xs font-medium">
                          Origin
                        </p>
                        <p className="font-semibold text-sm">
                          LOS-ANGELEX-USLAX
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-400 text-xs font-medium">
                          Destination
                        </p>
                        <p className="font-semibold text-sm">SHANGHAI</p>
                      </div>
                      <div>
                        <p className="text-gray-400 text-xs font-medium">
                          Weight
                        </p>
                        <p className="font-semibold text-sm">0</p>
                      </div>
                      <div>
                        <p className="text-gray-400 text-xs font-medium">
                          {y.services.length}
                          &nbsp;Locations
                        </p>
                        <p className="text-gray-400 text-xs font-medium">
                          {y.services.reduce((servSum, service) => {
                            return servSum + service.details.length;
                          }, 0)}
                          &nbsp;Services
                        </p>
                      </div>
                    </div>
                    <p className="font-semibold text-right mt-2">
                      {/* Optional  */}
                      Total: $
                      {y.services.reduce((servSum, service) => {
                        return (
                          servSum +
                          service.details.reduce((detSum, detail) => {
                            return detSum + detail.price;
                          }, 0)
                        );
                      }, 0)}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 top-2 right-5 bg-white py-2 px-2">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="inline-flex w-40 float-right justify-center items-center gap-2 py-1 px-2 border-2 border-sky-600 shadow-sm text-xs font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              onClick={printContent}
            >
              <img src="print.svg" className="h-5" /> Print
            </button>
          )}
          pageStyle={`
            @media print {
              @page {
                size: portrait;
                margin: 30px !important;
                border: 1px solid black !important; /* Page border */
      background-color: rgb(245 245 245) !important;
              }
              body {
                margin: 0;
                background-color:gray !important;
                -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
                print-color-adjust: exact !important; /* Firefox and Edge */
            }
               .noBorder{
  border: none !important;

  }
            }
          `}
          content={() => componentRef.current}
        />

        <button
          type="button"
          className="inline-flex float-right justify-center items-center gap-2 py-1 px-2 border-2 border-black shadow-sm text-xs font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          onClick={() => closeModal()}
        >
          <img src="x.svg" className="h-5" /> Close
        </button>
      </div>
    </div>
  );
};

export default QuotationDocument;
