import React, { useState, useEffect, useContext } from "react";
import { filterBy, process, orderBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import axios from "axios";
import { BASE_URL, movementStatuses } from "../../constants";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatDate } from "@telerik/kendo-intl";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import AppContext from "../../context/app-context";
import "../../index.scss";
import { Checkbox } from "@progress/kendo-react-inputs";
import KendoLoader from "../KendoLoader";
import { set } from "lodash";
import ReactModal from "react-modal";
import ScheduleForm from "./ScheduleForm";
import { ToastContainer } from "react-toastify";

const KendoDateCell = (props) => {
  const value = props.dataItem[props.field];
  return <td>{formatDate(new Date(value), "dd-MMM-yyyy")}</td>;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const gridColumns = [
  {
    field: "vesselName",
    title: "Vessel",
    width: 200,
  },
  {
    field: "poNo",
    title: "Po No",
    filterable: false,
    width: 200,
  },
  {
    field: "supplierName",
    title: "Supplier",
    filterable: false,
    width: 200,
  },
  {
    field: "colli",
    title: "Qty",
    filterable: false,
    width: 100,
  },
  {
    field: "weight",
    title: "Weight(KG)",
    filterable: false,
    width: 140,
  },
  {
    field: "length",
    title: "Length(CM)",
    filterable: false,
    width: 140,
  },
  {
    field: "width",
    title: "Width(CM)",
    filterable: false,
    width: 140,
  },
  {
    field: "height",
    title: "Height(CM)",
    filterable: false,
    width: 140,
  },
  {
    field: "chargableWeight",
    title: "Ch. Wt.(KG)",
    filterable: false,
    width: 140,
  },
  {
    field: "volume",
    title: "Volume(M3)",
    filterable: false,
    width: 140,
  },
  {
    field: "cargoType",
    title: "Cargo Type",
    filterable: false,
    width: 140,
  },
  {
    field: "currentWarehouse",
    title: "Warehouse",
    filterable: false,
    width: 150,
  },
  {
    field: "location",
    title: "Location",
    filterable: false,
    width: 150,
  },
  {
    field: "movementStatus",
    title: "Status",
    filterable: true,
    width: 150,
  },
  {
    field: "receivedDate",
    title: "Date",
    cell: KendoDateCell,
    filterable: false,
    width: 150,
  },
  {
    field: "shipmentRefNo",
    title: "AWB/BL",
    filterable: false,
    width: 150,
  },
  {
    field: "remarks",
    title: "Ref",
    filterable: false,
    width: 200,
  },
  {
    field: "cargoPickupType",
    title: "Shipment Type",
    filterable: false,
    width: 200,
  },
];

export default function StockReport() {
  const [vessels, setVessels] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [vessel, setVessel] = useState(null);
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState([
    movementStatuses.find((x) => x.id === 200),
  ]);
  const [canceledPos, setCanceledPos] = useState(false);
  //const defaultState = { skip: 0, take: 10 };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schedulerOpen, setSchedulerOpen] = useState(false);
  //const [pageState, setPageState] = useState(defaultState);

  const authCtx = useContext(AppContext);
  let _exporter;
  const excelExport = () => {
    save(_exporter);
  };

  const save = (component) => {
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;

    const dateColumn = rows[0].cells.find((x) => x.value === "Date");
    const dateIndex = rows[0].cells.indexOf(dateColumn);
    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells[dateIndex].value = formatDate(
          new Date(row.cells[dateIndex].value),
          "dd-MMM-yyyy"
        );
        row.cells.forEach((cell) => {
          if (cell.value === "Hazardous") cell.background = "#ff0000";
        });
      }
    });

    // const newData = orderBy(
    //   data,
    //   gridData.sort
    // );
    // component.save(data, options);
    _exporter.save(options);
  };

  const loadData = async () => {
    setLoading(true);
    const customerId = customer == null ? "~ALL" : customer.name;
    const vesselId = vessel == null ? "~ALL" : vessel.name;
    const statusId =
      status == null ? "~ALL" : status.map((s) => s.name).join(",");
    const searchLocation = location === "" ? "~ALL" : location;
    const res = await axios.get(
      `${BASE_URL}/Reports/stock?vessel=${encodeURIComponent(
        vesselId
      )}&customer=${encodeURIComponent(
        customerId
      )}&location=${encodeURIComponent(
        searchLocation
      )}&status=${statusId}&canceledPOs=${canceledPos}`
    );

    if (authCtx.profile.warehouseId) {
      const newRes = res.data.data.filter(
        (x) => x.warehouseId === authCtx.profile.warehouseId
      );
      setData(newRes);
    } else {
      setData(res.data.data);
    }
    setLoading(false);
    // setPageState((pre) => ({
    //   ...pre,
    //   total: res.data.totalCount,
    //   skip: res.data.pageNo,
    //   take: res.data.pageSize,
    // }));
  };

  const rowRender = (trElement, props) => {
    const danger = props.dataItem.cargoType === "Hazardous";
    const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };
    const trProps = { style: danger ? red : {} };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const search = async () => {
    await loadData();
  };

  const reset = () => {
    setData([]);
    //setPageState(defaultState)
    // setGridState(defaultState)
    setCustomer(null);
    setVessel(null);
    setStatus(null);
  };

  const onVesselChange = (e) => {
    setVessel(e.value);
  };

  const onStatusChange = (e) => {
    setStatus(e.value);
  };

  const onCustomerChange = (e) => {
    setCustomer(e.value);
  };

  const loadLookups = async () => {
    let res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    setCustomers(res.data);

    res = await axios.get(
      `${BASE_URL}/vessel?searchText=~ALL&page=1&pageSize=1000`
    );
    setVessels(res.data.data);
  };

  useEffect(() => {
    loadLookups();
    if (authCtx.profile.role === 300) {
      setLocation(authCtx.profile.warehouse.cityName);
    }
  }, []);

  return (
    <div className="mx-4 my-4 sm:mx-6 lg:mx-5">
      <div className="flex mb-4">
        <div className="flex min-w-0 gap-2 items-center">
          <h1 className="text-xl font-bold leading-6 text-gray-900 sm:truncate">
            Stock Report
          </h1>
          <button
            type="button"
            onClick={() => setSchedulerOpen(true)}
            className="h-5 w-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="-my-2 relative sm:-mx-6 lg:-mx-8 w-full">
          <div className="py-2 align-middle overflow-scroll inline-block w-full sm:pl-6 lg:pl-8">
            <div className="flex w-full items-center justify-items-center mb-6">
              <div className="w-1/6 h-8 stockMultiSelect mr-2" id="compo">
                <input
                  name="location"
                  className=" w-full h-full flex py-1 px-3 overflow-x-visible shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Enter Location"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                />
              </div>
              <div className="w-1/6 mr-2 h-8" id="compo">
                <ComboBox
                  data={customers}
                  name="customerId"
                  textField="name"
                  className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                  placeholder="Enter customer"
                  onChange={onCustomerChange}
                  value={customer}
                  required
                  suggest={true}
                  clearButton={false}
                />
              </div>
              <div className="w-1/6 mr-2 h-8" id="compo">
                <ComboBox
                  data={vessels}
                  name="vesselId"
                  textField="name"
                  className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                  placeholder="Enter vessel"
                  onChange={onVesselChange}
                  value={vessel}
                  suggest={true}
                  clearButton={false}
                />
              </div>
              <div className="w-1/6 h-8 stockMultiSelect" id="compo">
                <MultiSelect
                  data={movementStatuses}
                  name="statusId"
                  textField="name"
                  className=" w-full h-full flex py-1 overflow-x-visible shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Enter status"
                  onChange={onStatusChange}
                  value={status}
                  suggest={true}
                  clearButton={false}
                />
              </div>
              <div
                className="ml-3 flex items-center w-1/6 h-8 text-xs text-gray-800"
                id="compo"
              >
                <Checkbox
                  value={canceledPos}
                  checked={canceledPos}
                  onChange={() => setCanceledPos(!canceledPos)}
                />
                Show Canceled POS
              </div>

              <div class="flex justify-center mx-4">
                <button
                  className="inline-flex w-full justify-center px-4 items-center h-8 border border-lime-700 bg-lime-700 border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-lime-900 hover:border-lime-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" // disabled={
                  onClick={search}
                >
                  Search
                </button>
                <button
                  title="Export Excel"
                  className="inline-flex w-full justify-center px-4 ml-2 items-center h-8 border border-blue-700 bg-blue-700 border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-blue-900 hover:border-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  onClick={excelExport}
                >
                  Export
                </button>
                <button
                  className="inline-flex w-full justify-center px-4 ml-2 items-center h-8 border border-orange-700 bg-orange-700 border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-orange-900 hover:border-orange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" // disabled={
                  onClick={reset}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="shadow relative w-full overflow-x-auto border-b border-gray-200 sm:rounded-lg">
              {loading && <KendoLoader />}
              <ExcelExport
                ref={(exporter) => {
                  _exporter = exporter;
                }}
                data={data}
              >
                <Grid
                  data={data}
                  pageable={false}
                  filterable={false}
                  resizable={true}
                  rowRender={rowRender}
                  style={{
                    height: "70vh",
                    overflowY: "scroll",
                    overflowX: "scroll",
                    borderRadius: "10px",
                    borderColor: "#E5E7EB",
                    fontSize: "12px",
                    fontFamily: "poppins",
                    textAlign: "left",
                    width:
                      gridColumns.reduce((acc, column) => {
                        return acc + column.width;
                      }, 20) + "px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {gridColumns.map((c) => (
                    <GridColumn
                      field={c.field}
                      title={c.title}
                      width={c.width}
                      filterable={c.filterable}
                      cell={c?.cell}
                    />
                  ))}
                </Grid>
              </ExcelExport>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
      <ReactModal
        isOpen={schedulerOpen}
        //onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ScheduleForm
          closeModel={() => setSchedulerOpen(false)}
          customers={customers}
        />
      </ReactModal>
    </div>
  );
}
