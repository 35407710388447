import * as React from "react";
// import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
// import { Button } from '@progress/kendo-react-buttons';
export const CommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const [visible, setVisible] = React.useState(false);
  // const onDeleteData = () => {
  //   props.remove(props.dataItem);
  //   setVisible(!visible);
  // };
  // const toggleDialog = () => {
  //   setVisible(!visible);
  // };
  return (
    <td className="k-command-cell w-full">
      <button
        className="px-3 disabled:cursor-not-allowed border border-gray-400 rounded py-2 "
        disabled={inEdit ? props.validate(dataItem) : false}
        onClick={() => (inEdit ? props.update(dataItem) : props.edit(dataItem))}
      >
        {inEdit ? (
          <img src="tickIcon.svg" className="h-4" />
        ) : (
          <img src="editIcon.svg" className="h-4" />
        )}
      </button>
      <button
        className="px-3 border border-gray-400 ml-4 rounded py-2"
        onClick={() =>
          inEdit ? props.discard(dataItem) : props.remove(dataItem)
        }
      >
        {inEdit ? (
          <img src="closeIcon.svg" className="h-4" />
        ) : (
          <img src="deleteIcon.svg" className="h-4" />
        )}
      </button>
    </td>
  );
};
