import React, { useContext, useEffect, useState } from "react";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import ShipmentDetails from "./ShipmentDetails";
import axios from "axios";
import { BASE_URL, jobTypes, shipmentModes } from "../../constants";
import CargoImportPopup from "./CargoImportPopup";
import ReactModal from "react-modal";
import QuotationDocument from "./QuatationDocument";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../context/app-context";
import { validate } from "uuid";
import KendoLoader from "../KendoLoader";
import { debounce } from "lodash";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const CreateQuotation = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "1px solid #231f20",
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      // width: "40%",
      // marginTop: "50px",
      // marginBottom: "100px",
      transform: "translate(-50%, -50%)",
    },
  };
  const defaultService = {
    id: Date.now().toString(),
    expectedDate: new Date(),
    quantity: 0,
    price: 0,
    customClarence: "",

    details: [],
  };
  const defaultOption = {
    id: Date.now().toString(),
    origin: "",
    destination: "",
    expectedDate: new Date(),
    status: "Draft",
    isNew: true,
    comment: "",
    cargoDetailsOptions: {
      id: Date.now().toString(),
      quantity: 0,
      grossWeight: 0,
      grossVolume: 0,
      chargeWeight: 0,
      weight: 0,
      cargoes: [],
    },
    services: [defaultService],
  };
  const defaultShipment = {
    mode: "Air",
    options: [defaultOption],
  };
  const defaultForm = {
    id: "new",
    customer: null,
    originPort: null,
    destinationPort: null,
    jobType: null,
    modes: [],
    currency: null,
    quotationDate: new Date(),
    validityDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
    cargoDetails: {
      quantity: 0,
      grossWeight: 0,
      grossVolume: 0,
      chargeWeight: 0,
      cargoes: [],
    },
    shipment: [],
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const [quotation, setQuotation] = useState(defaultForm);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [originPorts, setOriginPorts] = useState([]);
  const [destinPorts, setDestinPorts] = useState([]);
  const [selectedMode, setSelectedMode] = useState();
  const [customers, setCustomers] = useState([]);
  const [logisticServices, setLogisticServices] = useState([]);
  const [modalIsOpen, setModelIsOpen] = useState(false);
  const [printModalIsOpen, setPrintModelIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const filterList = debounce(async (e) => {
    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "customer":
        res = await axios.get(
          `${BASE_URL}/Lookup/LimitedCustomers?searchText=${searchText}`
        );
        setCustomers(res.data);
        break;
      case "originPort":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=10&countryCode=${null}`
        );
        setOriginPorts(res.data);
        break;
      case "destinationPort":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=10&countryCode=${null}`
        );
        setDestinPorts(res.data);
        break;
      case "vessel":
        res = await axios.get(
          `${BASE_URL}/Lookup/Vessels?searchText=${searchText}&take=20`
        );
        setVessels(res.data);
        break;
      case "currency":
        if (searchText === "~ALL") setCurrencies(allCurrencies);
        else {
          const results = allCurrencies.filter(
            (currency) =>
              currency.code.toLowerCase().includes(searchText.toLowerCase()) ||
              currency.name.toLowerCase().includes(searchText.toLowerCase())
          );

          setCurrencies(results);
        }
        break;

      default:
        break;
    }
  }, 300);

  const validateForm = () => {
    const isValid =
      quotation?.customer &&
      quotation?.originPort &&
      quotation?.destinationPort &&
      quotation.currency &&
      quotation.modes.length > 0;
    return isValid;
  };

  const validateServices = (data) => {
    // Iterate through each shipment
    for (const shipment of data.shipment) {
      // Iterate through each option in shipment
      for (const option of shipment.options) {
        // Iterate through each service in options
        for (const service of option.services) {
          // Check if the service's details array has at least one item
          if (!service.details || service.details.length === 0) {
            // If a service has no details, return false and specify which service failed
            return {
              isValid: false,
              message: `Service with ID ${service.id} in option ${option.id} has no details selected.`,
            };
          }
        }
      }
    }
    // If all services have at least one detail, return valid
    return {
      isValid: true,
      message: "All services have at least one detail selected.",
    };
  };
  // useEffect(() => {
  //   validateForm();
  // }, [quotation]);

  const handleOnCancel = () => {
    navigate(`/quotations`);
  };

  const handleSave = async () => {
    if (!validateForm()) {
      toast.warning("Please fill in all mandatory fields.");
      return;
    }
    const vaildate = validateServices(quotation);
    if (!vaildate.isValid) {
      toast.warning("Please select at least one service.");
      return;
    } else {
      // setStatus("Draft");
      // setShowStatusComponent(true);
    }
    let payload = {
      ...quotation,
      jobType: quotation?.jobType?.id,
      modes: quotation.modes.map((x) => x.id),
      currencyCode: quotation.currency?.code,
    };
    setRequesting(true);
    if (id === "new") {
      const res = await axios.post(`${BASE_URL}/Quotation`, payload);

      if (res.status === 200) {
        toast.success("Quotation created successfully!");
        navigate(`/quotation/${res.data}`);
        document.location.reload();
      } else {
        toast.error("An error occured while creating Quotation!");
      }
    } else {
      const res = await axios.put(`${BASE_URL}/Quotation`, payload);

      if (res.status === 200) {
        toast.success("Quotation Updated successfully!");
        configureQuotationData(res.data);
        navigate(`/quotation/${res.data.id}`);
        // document.location.reload();
      } else {
        toast.error("An error occured while updating Quotation!");
      }
    }
    setRequesting(false);
  };

  const handleCompoBoxChange = (event, fieldType) => {
    const { value, name, props } = event.target;
    if (fieldType === "port") {
      setQuotation((prev) => ({
        ...prev,
        [name + "Id"]: value ? value[props.id] : null,
        [name]: value,
        shipment: prev.shipment.map((x) => ({
          ...x,
          options: x.options.map((x) => ({
            ...x,
            [name + "Id"]: value ? value[props.id] : null,
            [name]: value,
          })),
        })),
      }));
    } else if (name === "customer") {
      setQuotation((prev) => ({
        ...prev,
        [name + "Id"]: value ? value[props.id] : null,
        [name]: value,
        cargoDetails: {
          ...prev.cargoDetails,
          cargoes: [],
        },
      }));
    } else
      setQuotation((prev) => ({
        ...prev,
        [name + "Id"]: value ? value[props.id] : null,
        [name]: value,
      }));
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setQuotation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCargoDetailChange = (event, field) => {
    const { value } = event.target;
    setQuotation((prev) => ({
      ...prev,
      cargoDetails: {
        ...prev.cargoDetails,
        [field]: value,
      },
    }));
  };

  const loadCurrencies = async () => {
    const res = await axios.get(`${BASE_URL}/Lookup/Currencies`);
    setCurrencies(res.data);
    setAllCurrencies(res.data);
  };

  const loadVessels = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Vessels?searchText=~ALL&take=20`
    );
    setVessels(res.data);
  };

  const handleSelectChange = (e) => {
    setQuotation((pre) => ({ ...pre, modes: e.value }));
  };

  const loadPorts = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Ports?searchText=~ALL&take=10&countryCode=${null}`
    );
    setOriginPorts(res.data);
    setDestinPorts(res.data);
  };

  const closePoModal = () => {
    setModelIsOpen(false);
  };

  const closePrintModal = () => {
    setPrintModelIsOpen(false);
  };

  const loadCustomers = async () => {
    let res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    setCustomers(res.data);
  };

  const loadLogisticServices = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/LogisticsServices?searchText=~ALL`
    );
    setLogisticServices(res.data);
  };

  const handleVesselChange = (e) => {
    setQuotation((pre) => ({ ...pre, vessels: e.value }));
  };

  const handleDateChange = (date, dateString, name) => {
    if (date) {
      // Convert Moment.js object to JavaScript Date
      const selectedDate = date.toDate();
      selectedDate.setHours(12, 0, 0); // Adjust time to noon if needed

      if (name === "quotationDate") {
        const validityDate = new Date(selectedDate);
        validityDate.setDate(selectedDate.getDate() + 2); // Create a new Date for validityDate

        setQuotation((pre) => ({
          ...pre,
          [name]: selectedDate, // Save JavaScript Date object in state
          validityDate, // Save validityDate as a Date object
        }));
      } else {
        setQuotation((pre) => ({
          ...pre,
          [name]: selectedDate, // Save JavaScript Date object in state
        }));
      }
    } else {
      setQuotation((pre) => ({
        ...pre,
        [name]: null, // Handle case when the user clears the input
      }));
    }
  };

  const handleSelectChanges = (event) => {
    setQuotation((prev) => {
      return { ...prev, modes: event.value };
    });
    setQuotation((prev) => {
      const updatedShipment = event.value.map((mode) => {
        const existingShipment = prev.shipment.find(
          (shipment) => shipment.mode === mode.id
        );
        if (!existingShipment) {
          const origin = {
            id: quotation.originPortId,
            name: quotation.originPortName,
          };
          const destination = {
            id: quotation.destinationPortId,
            name: quotation.destinationPortName,
          };
          return {
            mode: mode.id,
            options: [
              {
                ...defaultOption,
                destinationPort: destination,
                destinationPortId: destination.id,
                originPort: origin,
                originPortId: origin.id,
                cargoDetailsOptions: {
                  ...defaultOption.cargoDetailsOptions,
                  quantity: prev.cargoDetails.quantity,
                  grossVolume: prev.cargoDetails.grossVolume,
                  grossWeight: prev.cargoDetails.grossWeight,
                  chargeWeight: prev.cargoDetails.chargeWeight,
                },
              },
            ],
          };
        }
        return existingShipment;
      });

      return { ...prev, shipment: updatedShipment };
    });
    if (!selectedMode && event.value.length !== 0)
      setSelectedMode(event.value[0].id);
  };

  const saveCargoes = (selectedCargoes) => {
    const uniqueArray = [
      ...new Map(selectedCargoes.map((item) => [item.id, item])).values(),
    ];
    const selectedVessels = [
      ...new Map(selectedCargoes.map((item) => [item.vesselId, item])).values(),
    ];
    setQuotation((pre) => ({
      ...pre,
      cargoDetails: {
        ...pre.cargoDetails,
        vessels: selectedVessels.map((x) => ({
          id: x.vesselId,
          name: x.vesselName,
        })),
        cargoes: selectedCargoes.map((x) => ({
          cargoId: x.id,
          packageId: x.package.id,
        })),
        grossVolume: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.volume, 0)
            ?.toFixed(3)
        ),
        grossWeight: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.weight, 0)
            ?.toFixed(3)
        ),
        quantity: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.colli, 0)
            ?.toFixed(3)
        ),
        chargeWeight: parseFloat(
          selectedCargoes
            .reduce((total, value) => total + value.package.colli, 0)
            ?.toFixed(3)
        ),

        posCount: uniqueArray.length,
      },
      shipment: pre.shipment.map((x) => ({
        ...x,
        options: x.options.map((y) => ({
          ...y,
          cargoDetailsOptions: {
            ...y.cargoDetailsOptions,
            grossVolume: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.volume, 0)
                ?.toFixed(3)
            ),
            grossWeight: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.weight, 0)
                ?.toFixed(3)
            ),
            quantity: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.colli, 0)
                ?.toFixed(3)
            ),
            chargeWeight: parseFloat(
              selectedCargoes
                .reduce((total, value) => total + value.package.colli, 0)
                ?.toFixed(3)
            ),
          },
        })),
      })),
    }));
  };

  const configureQuotationData = (data) => {
    setQuotation({
      ...data,
      quotationDate: data?.quotationDate || new Date(),
      validityDate:
        data?.validityDate || new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),

      currency: { code: data.currencyCode, name: data.currencyName },
      customer: { id: data.customerId, name: data.customerName },
      originPort: { id: data.originPortId, name: data.originPortName },
      destinationPort: {
        id: data.destinationPortId,
        name: data.destinationPortName,
      },
      jobType: jobTypes.find((x) => x.id === data.jobType),
      modes: shipmentModes.filter((x) => data.modes.includes(x.id)),
      shipment: data.shipment.map((x) => ({
        ...x,
        options: x.options.map((y) => ({
          ...y,
          originPort: { id: y.originPortId, name: y.originName },
          destinationPort: { id: y.destinationPortId, name: y.destinationName },
          services: y.services.map((z) => ({
            ...z,
            details: z.details.map((h) => ({ ...h, index: h.id })),
          })),
        })),
      })),
    });
  };

  const loadQuotation = async () => {
    setFormLoading(true);
    const res = await axios.get(`${BASE_URL}/Quotation/GetById?id=${id}`);
    let data = res.data;
    setSelectedMode(
      shipmentModes.filter((x) => data.modes.includes(x.id))?.at(0)?.id
    );
    configureQuotationData(data);
    setFormLoading(false);
  };

  useEffect(() => {
    loadCurrencies();
    loadPorts();
    loadCustomers();
    loadLogisticServices();
    loadVessels();
    if (id !== "new") loadQuotation();
  }, []);
  return (
    <div className="p-8 overflow-y-auto h-[90vh]">
      {formLoading && <KendoLoader />}
      <h1 className="text-2xl font-bold mb-4">
        {id !== "new" ? (
          <span>
            Edit Quotation :{" "}
            <span className="text-blue-400">{quotation.quotationNo}</span>
          </span>
        ) : (
          "Create New Quotation"
        )}
      </h1>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="col-span-2 bg-white rounded-md p-4">
          <div className="font-semibold pb-2">Quotation Details</div>
          <div className=" grid grid-cols-3 gap-4 ">
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Customer{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                data={customers}
                placeholder="Customer"
                name="customer"
                id="id"
                textField="name"
                onFilterChange={filterList}
                filterable
                value={quotation.customer}
                onChange={(e) => handleCompoBoxChange(e)}
              />
            </div>
            <div className=" bg-white col-span-2" id="poCompo">
              <div className="text-xs pb-1">
                Mode <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <MultiSelect
                className="w-full h-8 overflow-auto flex items-center rounded-md border border-zinc-300 text-xxs text-neutral-700"
                data={shipmentModes}
                textField="mode"
                dataItemKey="id"
                value={quotation.modes}
                tagRender={(tagData, li) =>
                  tagData.data.map((x) => (
                    <span
                      key={x.id}
                      className="mr-1 bg-neutral-600 text-white w-auto px-2 text-xs py-1 font-medium flex justify-between items-center rounded h-fit"
                    >
                      {x.mode}
                      <img src={x.icon} className="h-4 px-1" />
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          const updatedModes = quotation.modes.filter(
                            (mode) => mode.id !== x.id
                          );
                          handleSelectChanges({ value: updatedModes });
                        }}
                        className="mx-1 text-md text-white hover:text-neutral-200 focus:outline-none"
                      >
                        &times;
                      </button>
                    </span>
                  ))
                }
                placeholder="Mode Type"
                onChange={handleSelectChanges}
              />
            </div>
            <div className=" bg-white " id="poCompo">
              <div className="text-xs pb-1">
                Currency{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                data={currencies}
                name="currency"
                filterable
                textField="code"
                className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                dataItemKey="code"
                id="code"
                suggest={true}
                clearButton={true}
                onFilterChange={filterList}
                placeholder="currency"
                onChange={(e) => handleCompoBoxChange(e)}
                value={quotation.currency}
              />
            </div>
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Origin{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                data={originPorts}
                textField="name"
                name="originPort"
                filterable
                id="code"
                onFilterChange={filterList}
                placeholder="Origin"
                value={quotation.originPort}
                onChange={(e) => handleCompoBoxChange(e, "port")}
              />
            </div>
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Destination{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <ComboBox
                className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                data={destinPorts}
                id="code"
                textField="name"
                placeholder="Destination"
                filterable
                name="destinationPort"
                onFilterChange={filterList}
                value={quotation.destinationPort}
                onChange={(e) => handleCompoBoxChange(e, "port")}
              />
            </div>
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Quotation Date{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <DatePicker
                name="expectedDate"
                className="h-8 w-full py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Quotation Date"
                format="DD-MM-YYYY" // Specify your desired format
                value={
                  quotation.quotationDate
                    ? dayjs(quotation.quotationDate)
                    : null
                }
                required={false}
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString, "quotationDate")
                }
              />
            </div>{" "}
            <div className=" bg-white" id="poCompo">
              <div className="text-xs pb-1">
                Validity Date{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <DatePicker
                name="expectedDate"
                className="h-8 w-full py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Validity Date"
                format="DD-MM-YYYY" // Specify your desired format
                value={
                  quotation.validityDate ? dayjs(quotation.validityDate) : null
                }
                required={false}
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString, "validityDate")
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-white rounded-md p-4">
          <div className="font-semibold pb-2 flex justify-between">
            Cargo Details
            <div className="flex gap-2">
              {quotation.cargoDetails.cargoes.length > 0 &&
                quotation.cargoDetails.cargoes.length}
              <button
                disabled={!quotation.customer}
                title="Import PO"
                className="disabled:opacity-25"
                onClick={() => setModelIsOpen(true)}
              >
                <img src="import.svg" className="h-5" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mt-1 col-span-1">
              <div className="text-xs pb-1">
                Pallets/Pcs{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <input
                className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                type="number"
                placeholder="Quantity"
                value={quotation.cargoDetails.quantity}
                onChange={(e) => handleCargoDetailChange(e, "quantity")}
              />
            </div>

            <div className="mt-1 col-span-1">
              <div className="text-xs pb-1">
                Chg Weight (Kg){" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <input
                className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                type="number"
                placeholder="Charge Weight"
                value={quotation.cargoDetails.chargeWeight}
                onChange={(e) => handleCargoDetailChange(e, "chargeWeight")}
              />
            </div>

            <div className="mt-1 col-span-1">
              <div className="text-xs pb-1">
                Gross Weight (Kg){" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <input
                className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                type="number"
                placeholder="Gross Weight"
                value={quotation.cargoDetails.grossWeight}
                onChange={(e) => handleCargoDetailChange(e, "grossWeight")}
              />
            </div>

            <div className="mt-1 col-span-1">
              <div className="text-xs pb-1">
                Volume (m3){" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <input
                className="w-full p-2 h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                type="number"
                placeholder="Cargo Volume"
                value={quotation.cargoDetails.grossVolume}
                onChange={(e) => handleCargoDetailChange(e, "grossVolume")}
              />
            </div>

            <div className=" bg-white col-span-2" id="poCompo">
              <div className="text-xs pb-1">
                Vessel{" "}
                <span className="text-red-600 text-sm font-medium">*</span>
              </div>
              <MultiSelect
                className="w-full h-8 overflow-auto flex items-center rounded-md border border-zinc-300 text-xxs text-neutral-700"
                data={vessels}
                textField="name"
                dataItemKey="id"
                filterable
                name="vessel"
                onFilterChange={filterList}
                value={quotation?.cargoDetails?.vessels}
                tags={
                  quotation?.cargoDetails?.vessels?.length > 0
                    ? [
                        {
                          text: `${quotation?.cargoDetails?.vessels.length} Vessels selected`,
                          data: quotation?.cargoDetails?.vessels,
                        },
                      ]
                    : []
                }
                placeholder="Vessel"
                onChange={handleVesselChange}
              />
            </div>
          </div>
        </div>
      </div>
      {quotation.shipment.length > 0 && (
        <div className="bg-white rounded-md p-4">
          <div className="mb-6 ">
            <div className="flex gap-0 mb-6 w-full flex-col">
              <div className="flex w-fit">
                {quotation.modes.map(
                  (mode) => (
                    // quotation.modes.map((x) => x.id).includes(mode.id) && (
                    <div
                      key={mode.id}
                      className={`w-64 h-14 relative p-6 flex flex-col text-white shadow-lg items-center justify-center flex-1 text-center rounded-t-lg cursor-pointer bg-gradient-to-r  ${
                        selectedMode === mode.id
                          ? "bg-[#4c6df1] border"
                          : "text-gray-700 grayscale border border-b-0 border-neutral-300"
                      }`}
                      onClick={() => setSelectedMode(mode.id)}
                    >
                      <div className="flex items-center gap-3">
                        <div className="mx-auto mb-2">
                          <img src={mode.icon} className="h-9" />
                        </div>
                        <h2 className="text-xl font-bold">{mode.mode}</h2>
                      </div>
                    </div>
                  )

                  // )
                )}
              </div>

              <span className="border-t-4 border-blue-500 flex w-full" />
            </div>
          </div>

          {quotation.shipment.map(
            (i, index) =>
              i.mode === selectedMode && (
                <ShipmentDetails
                  key={index}
                  setQuotation={setQuotation}
                  quotation={quotation}
                  mode={i.mode}
                  shipment={i}
                  logisticServices={logisticServices}
                  destinationPorts={destinPorts}
                  originPorts={originPorts}
                  defaultOption={defaultOption}
                  defaultService={defaultService}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  filterList={filterList}
                />
              )
          )}
        </div>
      )}
      <div className="bg-white p-4 mt-3 rounded mb-4">
        <div className="font-semibold px-3 pb-2">Comments</div>
        <textarea
          className="w-full outline-none border-0 rounded"
          placeholder="Some notes about the Quotation"
          value={quotation?.comments}
          onChange={(e) => handleInputChange(e, "comments")}
        ></textarea>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setPrintModelIsOpen(true)}
          className="flex justify-center items-center disabled:opacity-40 disabled:cursor-not-allowed py-1 bg-blue-500 w-20 text-white border rounded-md"
          //   ${
          //   isSaved ? "bg-blue-500" : "bg-blue-500 cursor-not-allowed"
          // } w-20 text-white border rounded-md`}
          disabled={quotation.id === "new"}
        >
          Print
        </button>

        <button
          className="flex justify-center items-center py-1 bg-red-600 w-20 text-white border rounded-md"
          onClick={handleOnCancel}
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          disabled={requesting}
          className="px-4 py-2 rounded-md font-semibold flex items-center gap-1 disabled:opacity-30 bg-green-500 text-white cursor-pointer"
          //    ${isFormValid && pendingSelection ? "bg-green-500 text-white cursor-pointer" : "bg-green-500 text-white cursor-not-allowed"}
          // `}
        >
          {requesting && (
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save
        </button>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closePoModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="OverlayBlack"
        ariaHideApp={false}
      >
        <CargoImportPopup
          quotation={quotation}
          closeModal={closePoModal}
          onSavePo={saveCargoes}
        />
      </ReactModal>
      <ReactModal
        isOpen={printModalIsOpen}
        //onAfterOpen={afterOpenModal}
        // onRequestClose={closePrintModal}
        style={customStyles2}
        contentLabel="Example Modal"
        overlayClassName="OverlayWhite"
        ariaHideApp={false}
      >
        <QuotationDocument
          // className="w-full h-full  "
          quotation={quotation}
          closeModal={closePrintModal}
          onSavePo={saveCargoes}
        />
      </ReactModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default CreateQuotation;
