import { XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL, jobTypes } from "../../../constants";
import SlidingPane from "react-sliding-pane";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { debounce } from "lodash";

const ServiceMasterSlider = ({
  open,
  setOpen,
  serviceTemplate,
  setServiceTemplate,
  onUpdate,
  onCreate,
}) => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [originPorts, setOriginPorts] = useState([]);
  const [destinPorts, setDestinPorts] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState();

  const filterList = debounce(async (e) => {
    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "customerId":
        res = await axios.get(
          `${BASE_URL}/Lookup/Customers?searchText=${searchText}`
        );
        setCustomers(res.data);
        break;
      case "originId":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=40&countryCode=${null}`
        );
        setOriginPorts(res.data.map((x) => ({ id: x.code, name: x.name })));
        break;
      case "service":
        res = await axios.get(
          `${BASE_URL}/Lookup/LogisticsServices?${searchText}`
        );
        
        setServices(res.data.map((x) => ({ id: x.code, name: x.name })));
        break;
      case "destinId":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=40&countryCode=${null}`
        );
        setDestinPorts(res.data.map((x) => ({ id: x.code, name: x.name })));
        break;
      default:
        break;
    }
  }, 300);

  const onChange = (e) => {
    setServiceTemplate({ ...serviceTemplate, [e.target.name]: e.target.value });
  };

  const onCompoboxChange = (e) => {
    setServiceTemplate({
      ...serviceTemplate,
      [e.target.name]: e.value ? e.value.id : null,
    });
  };
  

  const onSubmit = async () => {
    setLoading(true);
    if (serviceTemplate.id) {
      const res = await axios.put(
        `${BASE_URL}/ServiceTemplate`,
        serviceTemplate
      );
      if (res.status === 200) {
        toast.success("Tariff Details updated Successfully!");
        onUpdate(serviceTemplate);
        setLoading(false);
        //setOpen(false);
      } else {
        toast.error("An error occured while updating Tariff!");
        setLoading(false);
      }
    } else {
      try {
        const res = await axios.post(
          `${BASE_URL}/ServiceTemplate`,
          serviceTemplate
        );
        toast.success(
          "Tariff Added Successfully!"
          //,{onClose: () => setTimeout(() => setOpen(false), 5000),}
        );
        onCreate(serviceTemplate, res.data);
        const data = { ...serviceTemplate, id: res.data };
        setServiceTemplate(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response.status === 400) {
          toast.error(e.response.data);
        } else {
          toast.error("An error occured while creating Tariff!");
        }
      }
    }
  };

  const loadServices = async () => {
    const res = await axios.get(
      `${BASE_URL}/LogisticsService?searchText=~ALL`
    );
    if (res.status === 200) {
      setServices(res.data.data);
    }
  };

  const loadCustomers = async () => {
    const res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    if (res.status === 200) {
      setCustomers(res.data);
    }
  };

  const loadPorts = async () => {
    const res = await axios.get(
      `${BASE_URL}/Lookup/Ports?searchText=~ALL&take=40&countryCode=${null}`
    );
    if (res.status === 200) {
      setOriginPorts(res.data.map((x) => ({ id: x.code, name: x.name })));
      setDestinPorts(res.data.map((x) => ({ id: x.code, name: x.name })));
    }
  };

  const onServiceAdd = () => {
    setServiceTemplate((pre) => ({
      ...pre,
      logisticsServices: [...pre.logisticsServices, selectedService],
    }));

    setSelectedService(null);
  };

  const handleDeleteServices = async (id) => {
    setServiceTemplate((prev) => ({
      ...prev,
      logisticsServices: prev.logisticsServices.filter((x) => x.id !== id),
    }));
  };
 
  const onPriceChange = (e, id) => {
    setServiceTemplate((prev) => ({
      ...prev,
      logisticsServices: prev.logisticsServices.map((x) =>
        x.id === id ? { ...x, price: e.target.value } : x
      ),
    }));
  };

  const onDescriptionChange = (e, id) => {
    
    setServiceTemplate((prev) => ({
      ...prev,
      logisticsServices: prev.logisticsServices.map((x) =>
        x.id === id ? { ...x, description: e.target.value } : x
      ),
    }));
  };

  

  

  useEffect(() => {
    loadCustomers();
    loadPorts();
    loadServices();
  }, []);

  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={open}
      from="right"
      width="680px"
      className=""
    >
      <div className="relative inset-0 overflow-hidden">
        <div className="w-screen max-w-2xl bg-white shadow-xl h-[89vh] overflow-y-scroll">
          <div className="px-4 py-4 bg-indigo-50 sm:px-6 sticky top-0">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1 text-2xl">
                {serviceTemplate?.id
                  ? "Edit Tariff"
                  : "New Tariff"}
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <form className="h-full flex flex-col">
            <div className="flex-1">
              <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Name
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={onChange}
                      value={serviceTemplate.name}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 space-y-1 pb-4 px-4 sm:space-y-0 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="category"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Template Details
                      <span className="block text-red-500 text-xs mt-0.5 pl-1">
                        *(fill atleast one field)
                      </span>
                    </label>
                  </div>

                  <div className="mx-4 sm:col-span-2 border border-dashed rounded-md border-green-500">
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                      <div>
                        <label
                          htmlFor="name"
                          className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Customer
                        </label>
                      </div>
                      <div className=" w-full col-span-2" id="compo">
                        <ComboBox
                          data={customers}
                          textField="name"
                          dataItemKey="code"
                          id="countrycode"
                          name="customerId"
                          suggest={true}
                          clearButton={true}
                          filterable={true}
                          onFilterChange={filterList}
                          className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={onCompoboxChange}
                          value={
                            serviceTemplate.customerId !== null
                              ? customers.find(
                                  (x) => x.id === serviceTemplate.customerId
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                      <div>
                        <label
                          htmlFor="name"
                          className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Job Type
                        </label>
                      </div>
                      <div className=" w-full col-span-2" id="compo">
                        <ComboBox
                          data={jobTypes}
                          textField="name"
                          dataItemKey="id"
                          id="jobType"
                          name="jobType"
                          suggest={true}
                          clearButton={true}
                          filterable={true}
                          //   onFilterChange={(e) =>
                          //     filterComboBoxData(e.filter, e.target.name)
                          //   }
                          className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={onCompoboxChange}
                          value={
                            serviceTemplate?.jobType !== null
                              ? jobTypes.find(
                                  (x) => x.id === serviceTemplate.jobType
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                    
                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                      <div>
                        <label
                          htmlFor="name"
                          className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Origin
                        </label>
                      </div>
                      <div className=" w-full col-span-2" id="compo">
                        <ComboBox
                          data={originPorts}
                          textField="name"
                          dataItemKey="code"
                          id="countrycode"
                          name="originPortId"
                          suggest={true}
                          clearButton={true}
                          filterable={true}
                          onFilterChange={filterList}
                          className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={onCompoboxChange}
                          value={
                            serviceTemplate.originPortId !== null
                              ? originPorts.find(
                                  (x) => x.id === serviceTemplate.originPortId
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                      <div>
                        <label
                          htmlFor="name"
                          className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Destination
                        </label>
                      </div>
                      <div className=" w-full col-span-2" id="compo">
                        <ComboBox
                          data={destinPorts}
                          textField="name"
                          dataItemKey="code"
                          id="countrycode"
                          name="destPortId"
                          suggest={true}
                          clearButton={true}
                          filterable={true}
                          className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onFilterChange={filterList}
                          onChange={onCompoboxChange}
                          value={
                            serviceTemplate.destPortId !== null
                              ? destinPorts.find(
                                  (x) => x.id === serviceTemplate.destPortId
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 space-y-4 sm:gap-4 sm:px-6 sm:py-4">
                  <div className="space-y-1 gap-3 sm:space-y-0 sm:grid sm:grid-cols-5">
                    <div className="col-span-2">
                      <label
                        htmlFor="name"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Services
                        <span className="block text-red-500 mt-0.5 pl-1">
                          *
                        </span>
                      </label>
                    </div>
                    <div className="mr-4 w-full col-span-2" id="compo">
                      <ComboBox
                        data={services}
                        textField="name"
                        dataItemKey="id"
                        id="service"
                        name="service"
                        suggest={true}
                        clearButton={true}
                        filterable={true}
                        onFilterChange={filterList}
                        className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => setSelectedService(e.target.value)}
                        value={selectedService}
                      />
                    </div>
                    <button
                      disabled={selectedService == null}
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:cursor-not-allowed"
                      onClick={() => onServiceAdd()}
                    >
                      + Add
                    </button>
                  </div>
                  <div class=" max-h-[65vh] border bg-gray-300 p-0 border-gray-300 overflow-auto">
                    <table class="w-full h-full text-xs text-left text-gray-500 border rounded-lg shadow-md sm:rounded-lg">
                      <thead class="text-xxs sticky znone z-10 top-0 border-gray-300 text-gray-700 uppercase bg-gray-200 rounded-t-lg">
                        <tr>
                          <th scope="col" class="py-3 px-2">
                            Code
                          </th>
                          <th scope="col" class="py-3 px-2">
                            Name
                          </th>
                          <th scope="col" class="py-3 px-2">
                            Price
                          </th>
                          <th scope="col" class="py-3 px-2">
                            Description
                          </th>
                          <th scope="col" class="py-3 px-2">
                            Action
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody className="max-h-40 min-h-fit overflow-y-scroll">
                        {serviceTemplate.logisticsServices &&
                        serviceTemplate.logisticsServices?.length > 0 ? (
                          serviceTemplate.logisticsServices.map((v, pid) => {
                            return (
                              <tr class="bg-white border-b h-full">
                                <td class="py-2 px-2 border-l">{v.code}</td>
                                <td class="py-2 px-2 border-l">{v.name}</td>
                                <td class="py-2 px-2 border-l w-24">
                                  <input
                                    type="number"
                                    name="price"
                                    id="price"
                                    required
                                    className="block w-20 shadow-sm sm:text-sm focus:ring-0 border-gray-300 rounded-md uppercase"
                                    onChange={(e) => onPriceChange(e, v.id)}
                                    value={v.price}
                                  />
                                </td>
                                <td class="py-2 px-2 border-l">
                                <input
                                    type="text"
                                    name="description"
                                    id="description"
                                    required
                                    className="block w-full shadow-sm sm:text-sm focus:ring-0 border-gray-300 rounded-md uppercase"
                                    onChange={(e) => onDescriptionChange(e, v.id)}
                                    value={v.description}
                                  />
                                </td>
                                <td class="py-2 px-2 border-l ">
                                  <img
                                    src="trash.svg"
                                    alt=""
                                    className="w-6 cursor-pointer"
                                    onClick={() => handleDeleteServices(v.id)}
                                  />
                                </td>
                                
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="bg-white border-b h-full">
                            <td
                              colSpan={6}
                              class="py-2 px-2 border-l text-center"
                            >
                              no records
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="fixed bottom-0 w-full px-4 border-t border-gray-200 py-4 sm:px-6">
                  <div className="space-x-3 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={onSubmit}
                      disabled={
                        serviceTemplate.name === "" ||
                        (!serviceTemplate.jobType &&
                          !serviceTemplate.customerId &&
                          !serviceTemplate.originPortId &&
                          !serviceTemplate.destPortId) ||
                        serviceTemplate.logisticsServices?.length === 0
                      }
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    >
                      {loading && (
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {serviceTemplate.id ? "Update" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
        </div>
      </div>
    </SlidingPane>
  );
};

export default ServiceMasterSlider;
