import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL, CargoPickupTypes } from "../../constants";
import { useNavigate } from "react-router-dom";
import SupplierSlider from "../masters/supplier/SupplierSlider";
// import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import AppContext from "../../context/app-context";
import PartySlider from "../masters/party/PartySlider";
import { DatePicker } from "antd";

import { filterBy } from "@progress/kendo-data-query";
import dayjs from "dayjs";

export const PoForm = ({ po, setPo, setModified }) => {
  const navigate = useNavigate();
  const [vessels, setVessels] = useState([]);
  const [filteredVessels, setFilteredVessels] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [filteredWarehouse, setFilteredWarehouse] = useState([]);
  const [country, setCountry] = useState([]);
  const [filteredCountry, setFilteredCountry] = useState([]);
  const [open, setOpen] = useState(false);

  const authCtx = useContext(AppContext);

  const defaultEntity = {
    id: 0,
    code: "",
    name: "",
    address: "",
    country: "",
    customer: false,
    supplier: true,
    agent: false,
    shipper: false,
    consignee: false,
    notifyParty: false,
    active: true,
    poLevelShipper: true,
  };

  // const FilterData = (e) => {
  //   const data = compoBoxData.slice();
  //   return filterBy(data, filter);
  // };
  const filterList = async (e) => {
    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "vessel":
        res = await axios.get(
          `${BASE_URL}/Lookup/Vessels?searchText=${searchText}&take=20`
        );
        setFilteredVessels(res.data);
        break;
      case "warehouse":
        res = await axios.get(
          `${BASE_URL}/Lookup/Warehouses?searchText=${searchText}&take=10&countryCode=null                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       `
        );
        setFilteredWarehouse(res.data);
        break;
      case "supplier":
        res = await axios.get(
          `${BASE_URL}/Lookup/Suppliers?searchText=${searchText}&take=10                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       `
        );
        setFilteredSuppliers(res.data);
        break;
      case "country":
        res = await axios.get(
          `${BASE_URL}/Lookup/Countries?searchText=${searchText}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     `
        );
        setFilteredCountry(res.data);
        break;
      default:
        break;
    }
  };

  const onSupplierCreated = (newSupplier, SupplierId) => {
    setSuppliers([{ ...newSupplier, id: SupplierId }, ...suppliers]);
  };

  const onCheckBoxChange = (e) => {
    setModified(true);
    setPo((es) => ({ ...es, [e.target.name]: e.target.checked }));
  };
  
  const handleDateChange = (date, dateString) => {
    if (date) {
      // Convert moment object to a JavaScript Date
      const selectedDate = new Date(dateString); // Parse the date string into a JavaScript Date
      selectedDate.setHours(12, 0, 0); // Adjust the time to noon if needed
      setPo({
        ...po,
        receivedDate: selectedDate, // Save JavaScript Date object in state
      });
    } else {
      setPo({
        ...po,
        receivedDate: null, // Handle case when the user clears the input
      });
    }
  };

  const onValueChange = (e) => {
    setModified(true);
    setPo((es) => ({ ...es, [e.target.name]: e.target.value }));
  };
  const onCooChange = (e) => {
    setModified(true);
    if (e.value) {
      setPo((es) => ({
        ...es,
        cooCode: e.value.code,
        coo: e.value,
      }));
    } else {
      setPo((es) => ({
        ...es,
        cooCode: null,
      }));
    }
  };

  const onWarehouseChange = (e) => {
    setModified(true);
    if (e.value) {
      setPo((es) => ({
        ...es,
        warehouseId: e.value.id,
        warehouse: e.value,
        agentId: e.value.agentId,
        agentName: e.value.agentName,
        warehouseCity: e.value.cityName,
      }));
    } else {
      setPo((es) => ({
        ...es,
        warehouseId: null,
        agentId: null,
      }));
    }
  };

  const onVesselChange = (e) => {
    setModified(true);
    if (e.value) {
      setPo((es) => ({
        ...es,
        vessel: e.value,
        vesselId: e.value.id,
        customerId: e.value.customerID,
        customerName: e.value.customerName,
      }));
    } else {
      setPo((es) => ({
        ...es,
        vesselId: null,
        customerId: null,
      }));
    }
  };

  const onSupplierChange = (e) => {
    setModified(true);
    if (e.value) {
      setPo((es) => ({
        ...es,
        supplierId: e.value.id,
        supplier: e.value,
        supplierAddress: e.value.address,
        supplierPhone:
          e.value.contacts?.length > 0 ? e.value.contacts[0].phone : "",
        supplierEmail:
          e.value.contacts?.length > 0 ? e.value.contacts[0].email : "",
      }));
    } else {
      setPo((es) => ({
        ...es,
        supplierId: null,
      }));
    }
  };

  const loadLookups = async () => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Vessels?searchText=~ALL&take=20`
    );
    //setVessels(res.data);
    setFilteredVessels(res.data);

    res = await axios.get(
      `${BASE_URL}/Lookup/Countries?searchText=~ALL                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     `
    );
    setCountry(res.data);
    setFilteredCountry(res.data);

    res = await axios.get(
      `${BASE_URL}/Lookup/Suppliers?searchText=~ALL&take=10                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       `
    );
    setSuppliers(res.data);
    setFilteredSuppliers(res.data);

    res = await axios.get(`${BASE_URL}/Lookup/Currencies`);
    setCurrencies(res.data);

    res = await axios.get(
      `${BASE_URL}/Lookup/Warehouses?searchText=~ALL&take=1000&countryCode=null`
    );
    setWarehouse(res.data);
    setFilteredWarehouse(res.data);
  };

  const onCreateClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    loadLookups();
  }, []);

  // const valueRender = (dataItem) => {
  //   return `${dataItem?.name} ${dataItem?.cityName}`;
  // };

  useEffect(() => {
    if (authCtx.profile.role === 300) {
      const wh = warehouse.find((x) => x.id === authCtx.profile.warehouseId);
      if (wh != null) {
        setPo({
          ...po,
          warehouseId: wh.id,
          warehouse: wh,
          agentId: wh.agentId,
          agentName: wh.agentName,
          warehouseCity: wh.cityName,
        });
      }
    }
  }, [warehouse, authCtx]);

  return (
    <div className="xl:w-3/4 md:w-3/4 sm:w-3/6 xs:w-3/6 ">
      {/* <p className="font-semibold text-lg text-black px-5 py-3">PO Details</p> */}
      <form className="space-y-8 divide-y divide-gray-200 overflow-auto">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-6 sm:gap-y-4 h-[67vh]  p-5 overflow-y-auto">
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
              <label
                htmlFor="poNo"
                className="block text-sm font-normal text-black"
              >
                PO Number
                <span className="text-red-600 text-sm font-medium">*</span>
              </label>
              <div className="mt-1 flex h-9">
                <input
                  type="text"
                  name="poNo"
                  id="poNo"
                  placeholder="Enter PO Number"
                  required
                  className="h-full capitalize block w-full text-xs font-normal text-neutral-700 placeholder:text-neutral-400 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-zinc-300 rounded-md"
                  value={po.poNo}
                  onChange={onValueChange}
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 h-full">
              <label
                htmlFor="vessels"
                className="block text-sm font-normal text-black"
              >
                Vessel
                <span className="text-red-600 text-sm font-medium">*</span>
              </label>
              <div className="mt-1 h-9" id="poCompo">
                <ComboBox
                  data={filteredVessels}
                  name="vessel"
                  textField="name"
                  dataItemKey="id"
                  className="sm:text-sm focus:ring-0 focus:border-0 h-full text-neutral-700 font-normal text-xs"
                  placeholder="Enter vessel"
                  onChange={onVesselChange}
                  value={po.vessel}
                  filterable={true}
                  onFilterChange={filterList}
                  required
                  suggest={true}
                  clearButton={true}
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
              <label
                htmlFor="customerId"
                className="block text-sm font-normal text-black"
              >
                Customer
                <span className="text-red-600 text-sm font-medium">*</span>
              </label>
              <div className="mt-1 flex h-9">
                <input
                  type="text"
                  name="customerId"
                  id="customerId"
                  required
                  disabled
                  className="block h-full w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded"
                  value={po.customerName}
                />
              </div>
            </div>
            <div className="bg-slate-100 relative xl:col-span-6 grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-6 border border-dashed border-black rounded-md px-4 mt-2 py-3">
              <div className="xl:col-span-2">
                <label
                  htmlFor="supplierId"
                  className="block absolute px-2 rounded-t-md -top-3 bg-slate-100 w-20 text-sm font-normal text-black"
                >
                  Supplier
                  <span className="text-red-600 text-sm font-medium">*</span>
                </label>
                <div className="mt-1 flex h-9 gap-1">
                  <div className="w-full" id="poCompo">
                    <ComboBox
                      data={filteredSuppliers}
                      name="supplier"
                      textField="name"
                      className="sm:text-sm focus:ring-0 border-0 py-2 px-3 h-full"
                      dataItemKey="id"
                      suggest={true}
                      clearButton={true}
                      placeholder="Enter supplier"
                      onChange={onSupplierChange}
                      filterable={true}
                      onFilterChange={filterList}
                      value={po.supplier}
                      required
                    />
                  </div>
                  <button type="button" onClick={onCreateClick} className="w-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="xl:col-span-2 sm:col-span-3 xs:col-span-6">
                <p className="text-xs font-medium text-black">Address</p>
                <div className="mt-1">
                  <p className="text-xxs font-normal text-black">
                    {po.supplierAddress}
                  </p>
                </div>
              </div>
              <div className="xl:col-span-2 sm:col-span-3 xs:col-span-6">
                <p className="text-xs font-medium text-black">Contact Info</p>
                <div className="mt-1">
                  <p className="font-normal text-xxs text-black">
                    Phone : {po.supplierPhone} <br /> Email : {po.supplierEmail}
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6">
              <label
                htmlFor="shipmentType"
                className="block text-sm font-normal text-black"
              >
                Shipment Type
              </label>
              <div className="mt-1 flex h-9" id="poCompo">
                <ComboBox
                  data={CargoPickupTypes}
                  name="cargoPickupType"
                  textField="name"
                  className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                  dataItemKey="id"
                  suggest={true}
                  placeholder="Shipment Type"
                  onChange={(e) => {
                    setModified(true);
                    setPo((es) => ({
                      ...es,
                      cargoPickupType: e.target.value
                        ? e.target.value.id
                        : e.target.value,
                      cargoPickupTypeObject: e.target.value,
                    }));
                  }}
                  value={po.cargoPickupTypeObject}
                  clearButton={true}
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 flex flex-col">
              <label
                htmlFor="totalValue"
                className="block text-sm font-normal text-black"
              >
                Total Value
              </label>
              <div className="mt-1 h-9 relative currency-selecton flex rounded focus:ring-blue-500 focus:border-blue-500">
                <input
                  type="number"
                  name="totalValue"
                  id="totalValue"
                  className="w-1/2 focus:border-r-0 block pl-3 rounded-tl-md focus:ring-blue-500 focus:border-blue-500 rounded-bl-md text-xs font-normal text-neutral-700 sm:text-sm border border-gray-300 border-r-0 py-2 px-3"
                  value={po.totalValue}
                  onChange={(e) => {
                    setModified(true);
                    setPo((es) => ({
                      ...es,
                      totalValue: parseFloat(e.target.value),
                    }));
                  }}
                />
                <div className="inline w-32 h-9" id="poCompo">
                  <ComboBox
                    data={currencies}
                    name="currencyCode"
                    textField="code"
                    className="sm:text-sm focus:ring-0 focus:border-l border-0 border-l border-gray-300 py-2 pl-3 h-full"
                    dataItemKey="code"
                    suggest={true}
                    clearButton={true}
                    onChange={(e) => {
                      setModified(true);
                      setPo((es) => ({
                        ...es,
                        currency: e.target.value,
                        currencyCode: e.target.value
                          ? e.target.value.code
                          : e.target.value,
                      }));
                    }}
                    value={po.currency}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6">
              <label
                htmlFor="cargoType"
                className="block text-sm font-normal text-black"
              >
                Classification
              </label>
              <div className="mt-1 py-2 h-9">
                <div className="flex">
                  <div className="mr-7">
                    <input
                      className="form-check-input form-check-input text-xs font-normal text-neutral-700 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="cargoType"
                      checked={po.cargoType === 100}
                      value={po.cargoType}
                      onChange={() => {
                        setModified(true);
                        setPo((po) => ({
                          ...po,
                          cargoType: 100,
                        }));
                      }}
                    />
                    <label
                      className="inline-block text-sm font-normal text-black"
                      htmlFor="cargoType"
                    >
                      General
                    </label>
                  </div>
                  <div className="form-check form-check-inline h-9">
                    <input
                      className="form-check-input form-check-input text-xs font-normal text-neutral-600 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="cargoType"
                      checked={po.cargoType === 200}
                      value={po.cargoType}
                      onChange={() => {
                        setModified(true);
                        setPo((po) => ({
                          ...po,
                          cargoType: 200,
                        }));
                      }}
                    />
                    <label
                      className="inline-block text-sm font-normal text-black"
                      htmlFor="inlineRadio2"
                    >
                      DG Cargo
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {po.cargoType === 200 && (
              <div className="col-span-6 grid grid-cols-6 sm:gap-y-6">
                <div className="xl:col-span-6 sm:col-span-6 xs:col-span-6 flex flex-col">
                  <label
                    htmlFor="cargoTypeDescription"
                    className="block text-sm font-normal text-black"
                  >
                    DG Cargo Description
                  </label>
                  <div className="mt-1 flex-1 h-9">
                    <input
                      type="text"
                      name="cargoTypeDescription"
                      id="cargoTypeDescription"
                      className="block h-full w-full shadow-sm text-xs font-normal text-neutral-600 sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      value={po.cargoTypeDescription}
                      onChange={onValueChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <div className="col-span-6 grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-6 sm:gap-y-6"> */}
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6">
              <label
                htmlFor="customerId"
                className="block text-sm font-normal text-black"
              >
                Commodity
              </label>
              <div className="mt-1 flex h-9">
                <input
                  type="text"
                  name="commodity"
                  id="commodity"
                  placeholder="description of Commodity"
                  className="h-full block w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  value={po.commodity}
                  onChange={onValueChange}
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6">
              <label
                htmlFor="warehouseId"
                className="block text-sm font-normal text-black"
              >
                Country Of Origin
              </label>
              <div className="mt-1 h-9" id="poCompo">
                <ComboBox
                  data={filteredCountry}
                  name="country"
                  textField="name"
                  className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                  dataItemKey="code"
                  placeholder="Origin Country"
                  suggest={true}
                  onChange={onCooChange}
                  value={po.coo}
                  clearButton={true}
                  filterable={true}
                  onFilterChange={filterList}
                />
              </div>
            </div>
            {po.cargoPickupType === 300 && (
              <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6">
                <label
                  htmlFor="customerId"
                  className="block text-sm font-normal text-black"
                >
                  Awb/BL/Ref. No
                </label>
                <div className="mt-1 flex h-9">
                  <input
                    type="text"
                    name="shipmentRefNo"
                    id="shipmentRefNo"
                    placeholder="Enter Awb/BL/Ref. No"
                    className="h-full block w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    value={po?.shipmentRefNo}
                    onChange={onValueChange}
                  />
                </div>
              </div>
            )}
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
              <label
                htmlFor="receivedDate"
                className="block text-sm font-normal text-black"
              >
                Recieved Date
              </label>
              <div
                className="mt-1 flex h-9 border-t border-x rounded-md border-gray-300"
                id="poDateCompo"
              >
                {/* <DateInput /> */}
                <DatePicker
                  name="receivedDate"
                  id="receivedDate"
                  className="h-full w-full py-2 px-3 placeholder:text-neutral-500 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  value={po.receivedDate ? dayjs(po.receivedDate) : null} // Use moment for value binding
                  onChange={handleDateChange} // Handle changes
                  format="YYYY-MM-DD" // Specify your desired format
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6">
              <label
                htmlFor="warehouseId"
                className="block text-sm font-normal text-black"
              >
                Warehouse
              </label>
              <div className="mt-1 h-9" id="poCompo">
                <ComboBox
                  data={filteredWarehouse}
                  name="warehouse"
                  textField="name"
                  className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                  dataItemKey="id"
                  placeholder="Warehouse"
                  suggest={true}
                  onChange={onWarehouseChange}
                  value={po.warehouse}
                  //valueRender={valueRender}
                  clearButton={true}
                  filterable={true}
                  onFilterChange={filterList}
                  disabled={
                    authCtx.profile.role === 300
                      ? true
                      : false || po.packages.some((x) => x.activeJobId != null)
                  }
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
              <label
                htmlFor="agentId"
                className="block text-sm font-normal text-black"
              >
                Agent
              </label>
              <div className="mt-1 flex h-9">
                <input
                  type="text"
                  name="agentId"
                  id="agentId"
                  placeholder="Agent"
                  disabled
                  className="block h-full w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  value={po.agentName}
                />
              </div>
            </div>
            <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
              <label
                htmlFor="warehouseCity"
                className="block text-sm font-normal text-black"
              >
                Warehouse Location
              </label>
              <div className="mt-1 flex h-9">
                <input
                  type="text"
                  name="warehouseCity"
                  id="warehouseCity"
                  placeholder="Warehouse City"
                  disabled
                  className="block h-full w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  value={po.warehouseCity}
                />
              </div>
            </div>
            {/* <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
              {po.cargoPickupType === 300 && (
                <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6 flex flex-col">
                  <label
                    htmlFor="warehouseCity"
                    className="block text-sm font-normal text-black"
                  >
                    Warehouse Location
                  </label>
                  <div className="mt-1 flex h-9">
                    <input
                      type="text"
                      name="warehouseCity"
                      id="warehouseCity"
                      placeholder="Warehouse City"
                      disabled
                      className="block h-full w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      value={po?.warehouseCity}
                    />
                  </div>
                </div>
              )}
            </div> */}
            <div className="sm:col-span-6 gap-2 flex">
              <div className="w-1/2">
                <label
                  htmlFor="remarks"
                  className="block text-sm font-normal text-black"
                >
                  Remarks
                </label>
                <div className="mt-1 h-9">
                  <textarea
                    rows={3}
                    name="remarks"
                    placeholder="Remarks"
                    id="remarks"
                    value={po.remarks}
                    onChange={onValueChange}
                    className="block w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="remarks"
                  className="block text-sm font-normal text-orange-600"
                >
                  Internal Notes
                </label>
                <div className="mt-1 h-9">
                  <textarea
                    rows={3}
                    name="internalNotes"
                    placeholder="Internal Notes"
                    id="remarks"
                    value={po.internalNotes}
                    onChange={onValueChange}
                    className="block w-full shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <PartySlider
        open={open}
        setOpen={setOpen}
        onCreate={onSupplierCreated}
        selectedEntity={defaultEntity}
      />
    </div>
  );
};
