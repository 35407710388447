import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useState } from "react";
import "../../index.scss";

const checkboxColumn = (props) => {
  return (
    <td>
      {props.dataItem[props.field] ? (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 text-center">
          Active
        </span>
      ) : (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800  text-center">
          Inactive
        </span>
      )}
    </td>
  );
};

const BaseMasterGrid = ({
  setPageState,
  pageState,
  config = {
    state: {
      skip: pageState.skip,
      take: pageState.take,
      sort: [{ field: "name", dir: "asc" }],
    },
    pageable: true,
    filterable: true,
    sortable: true,
  },
  data = [],
  columns = [],
  onEditClick,
  loadData,
  onGridFilter,
}) => {
  const [gridState, setGridState] = useState({ ...config.state });
  const handleGridDataStateChange = (e) => {
    setGridState(e.dataState);
    setPageState(e.dataState);
  };
  const onPageChange = (e) => {
    const state = { ...pageState, skip: e.page.skip, take: e.page.take };
    setPageState(state);
    loadData(pageState.value, state);
  };

  return (
    <Grid
      data={process(data, gridState)}
      pageable={config.pageable}
      filterable={config.filterable}
      sortable={config.sortable}
      className="h-[75vh] rounded"
      onPageChange={onPageChange}
      filter={pageState.filter}
      onFilterChange={onGridFilter}
      onDataStateChange={handleGridDataStateChange}
      onRowDoubleClick={(e) => onEditClick(e)}
      total={pageState.total}
      {...pageState}
      //{...gridState}
    >
      {columns.map((column) => {
        return (
          !column.hidden && (
            <GridColumn
              key={column.field}
              field={column.field}
              title={column.title}
              filterable={column.filterable ? true : false}
              sortable={column.sortable ? true : false}
              cell={column.isBoolean && checkboxColumn}
              width={column.width?column.width:"auto"}
            />
          )
        );
      })}
    </Grid>
  );
};

export default BaseMasterGrid;
