import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../constants";
import SlidingPane from "react-sliding-pane";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import "../../../index.scss";
import { filterBy } from "@progress/kendo-data-query";

const PortSlider = ({ open, setOpen, port, setPort, onUpdate, onCreate }) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);

  const FilterData = (filter, compoBoxData) => {
    const data = compoBoxData.slice();
    return filterBy(data, filter);
  };
  const filterList = (filter, name) => {
    let searchText = filter.value;
    switch (name) {
      case "country":
        setFilteredCountries(FilterData(filter, countries));
        break;
      case "city":
        if (filter.value === "") searchText = "~ALL";
        filterCities(searchText);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    axios.get(`${BASE_URL}/Lookup/Countries?searchText=~ALL`).then((res) => {
      setCountries(res.data);
      setFilteredCountries(res.data);
      if (port.jobLevelCountry) {
        setPort({
          ...port,
          country: res.data.find((c) => c.code === port.jobLevelCountry),
        });
      }
    });
  }, []);

  const filterCities = (filterValue) => {
    axios
      .get(
        `${BASE_URL}/Lookup/City?searchText=${filterValue}&countryCode=${port.countryCode}`
      )
      .then((res) => {
        setCities(res.data);
      });
  };

  useEffect(() => {
    filterCities("~ALL");
  }, [port.countryCode]);

  const onChange = (e) => {
    setPort({ ...port, [e.target.name]: e.target.value });
  };

  const onCountryChange = (e) => {
    setPort({
      ...port,
      country: e.value,
      countryCode: e.value?.code,
      countryName: e.value?.name,
    });
  };

  useEffect(() => {
    if (port.countryCode !== undefined) {
      setCities(cities.filter((x) => x.countryCode === port.countryCode));
    } else {
      setCities(cities);
    }
  }, [port.country]);

  const onCityChange = (e) => {
    setPort({
      ...port,
      city: e.value,
      cityId: e.value?.id,
      cityName: e.value?.name,
    });
  };

  const onCheckBox = (e) => {
    setPort({ ...port, [e.target.name]: e.target.checked });
  };

  const onSubmit = async () => {
    setLoading(true);
    if (port.data) {
      const res = await axios.put(`${BASE_URL}/Port`, port);
      if (res.status === 200) {
        toast.success("Port Details updated Successfully!");
        setLoading(false);
        //setTimeout(1000);
        onUpdate(port);
        //setOpen(false);
      } else {
        toast.error("An error occured while updating Port!");
        setLoading(false);
      }
    } else {
      try {
        const res = await axios.post(`${BASE_URL}/Port`, port);
        toast.success("Port Added Successfully!");
        onCreate(port, res.data);
        const data = { ...port, id: res.data };
        setPort(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response.status === 400) {
          toast.error(e.response.data);
        } else {
          toast.error("An error occured while creating Port!");
        }
      }
    }
  };

  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={open}
      from="right"
      width="680px"
      className=""
    >
      <div className="h-full w-screen max-w-2xl">
        <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
          <div className="flex-1 h-[75vh] overflow-y-scroll">
            <div className="px-4 py-4 bg-[#e9f4ff] sm:px-6 sticky top-0">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1 text-2xl">
                  {port.data ? "Edit Port" : "New Port"}
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200 ">
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="code"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Code
                    <span className="block text-red-500 mt-0.5 pl-1">*</span>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    name="code"
                    id="code"
                    required
                    className="block w-full shadow-sm disabled:bg-slate-100 sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                    onChange={onChange}
                    value={port.code}
                    disabled={port.data}
                  />
                </div>
              </div>
              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                <div>
                  <label
                    htmlFor="name"
                    className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Name
                    <span className="block text-red-500 mt-0.5 pl-1">*</span>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                    onChange={onChange}
                    value={port.name}
                  />
                </div>
              </div>

              <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                <label
                  htmlFor="countryCode"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Country
                </label>
                <div className="mt-1 col-span-2 w-full h-8" id="compo">
                  <ComboBox
                    data={filteredCountries}
                    textField="name"
                    dataItemKey="code"
                    id="countrycode"
                    name="country"
                    suggest={true}
                    filterable={true}
                    onFilterChange={(e) => filterList(e.filter, e.target.name)}
                    clearButton={true}
                    className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    onChange={onCountryChange}
                    value={port.country}
                  />
                </div>
              </div>
              <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                <label
                  htmlFor="cityCode"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  City
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 h-8" id="compo">
                  <ComboBox
                    data={cities}
                    textField="name"
                    dataItemKey="id"
                    id="cityId"
                    name="city"
                    className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={(e) => filterList(e.filter, e.target.name)}
                    onChange={onCityChange}
                    value={port.city}
                  />
                </div>
              </div>
              <fieldset>
                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <div>
                    <legend className="text-sm font-medium text-gray-900">
                      Airport
                    </legend>
                  </div>
                  <div className="space-y-5 sm:col-span-2">
                    <div className="space-y-5 sm:mt-0">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            name="isAirPort"
                            type="checkbox"
                            id="isAirPort"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            onChange={onCheckBox}
                            checked={port.isAirPort}
                            value={port.isAirPort}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <div>
                    <legend className="text-sm font-medium text-gray-900">
                      Seaport
                    </legend>
                  </div>
                  <div className="space-y-5 sm:col-span-2">
                    <div className="space-y-5 sm:mt-0">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="isSeaPort"
                            name="isSeaPort"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            onChange={onCheckBox}
                            checked={port.isSeaPort}
                            value={port.isSeaPort}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <div>
                    <legend className="text-sm font-medium text-gray-900">
                      Active
                    </legend>
                  </div>
                  <div className="space-y-5 sm:col-span-2">
                    <div className="space-y-5 sm:mt-0">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="active"
                            name="active"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            onChange={onCheckBox}
                            checked={port.active}
                            value={port.active}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6">
            <div className="space-x-3 flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                onClick={onSubmit}
                disabled={port.name === "" || port.code === ""}
              >
                {loading && (
                  <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {port.data ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <ToastContainer />
      </div>
    </SlidingPane>
  );
};
export default PortSlider;
