import { useState, useEffect, useContext } from "react";
import axios from "axios";
import AppContext from "../../../context/app-context";
import { BASE_URL } from "../../../constants";
import WarehouseSlider from "./WarehouseSlider";
import BaseMasterGrid from "../BaseMasterGrid";
import KendoLoader from "../../KendoLoader";

export default function WarehouseMaster() {
  const defaultEntity = {
    name: "",
    countryCode: "SG",
    countryName: "",
    isConsolidationCenter: false,
    active: true,
  };
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(defaultEntity);
  const { searchText } = useContext(AppContext);
  const [pageState, setPageState] = useState({ take: 10, skip: 0, total: 0 });

  const gridColumns = [
    { field: "name", title: "Name", filterable: true, sortable: true , width:"150"},
    { field: "agentName", title: "Agent" },
    { field: "countryName", title: "Country" },
    { field: "active", title: "Active", isBoolean: true },
  ];

  const onEditClick = (e) => {
    const editedItem = e.dataItem;
    setSelectedMaster({
      ...editedItem,
      city: { id: editedItem.cityId, name: editedItem.cityName },
    });
    setOpen(true);
  };

  const onCreateClick = () => {
    setSelectedMaster(defaultEntity);
    setOpen(true);
  };

  const loadData = async (searchText = "~ALL", state) => {
    setLoading(true);
    const res = await axios.get(
      `${BASE_URL}/warehouse?searchText=${searchText}&page=${state.skip}&pageSize=${state.take}`
    );
    setEntities(res.data.data);
    setPageState((pre) => ({
      ...pre,
      total: res.data.totalCount,
      skip: res.data.pageNo,
      take: res.data.pageSize,
      value: searchText,
    }));
    setLoading(false);
  };

  useEffect(() => {
    const searchText = "~ALL";
    loadData(searchText, pageState);
  }, []);

  useEffect(() => {
    if (searchText === "") loadData();
    else loadData(searchText, pageState);
  }, [searchText]);

  const onGridFilter = (e) => {
    let searchText = "";
    if (e.filter && e.filter.filters && e.filter.filters[0].value !== "") {
      searchText = e.filter.filters[0].value;
    } else {
      searchText = "~ALL";
    }
    const state = {
      ...pageState,
      filter: { ...e.filter },
      skip: 0,
      value: searchText,
    };
    setPageState(state);
    loadData(searchText, state);
  };

  const onUpdate = (item) => {
    setEntities(entities.map((c) => (c.id === item.id ? { ...item } : c)));
  };

  const onCreate = (item, id) => {
    setEntities([...entities, { ...item, id: id }]);
  };

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Warehouses
        </h3>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onCreateClick}
          >
            Create
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow relative overflow-hidden sm:rounded-lg">
              {loading && <KendoLoader />}
              <BaseMasterGrid
                data={entities}
                columns={gridColumns}
                onEditClick={onEditClick}
                setPageState={setPageState}
                loadData={loadData}
                pageState={pageState}
                onGridFilter={onGridFilter}
              ></BaseMasterGrid>
            </div>
          </div>
        </div>
      </div>
      <WarehouseSlider
        open={open}
        setOpen={setOpen}
        selectedMaster={selectedMaster}
        setSelectedMaster={setSelectedMaster}
        onUpdate={onUpdate}
        onCreate={onCreate}
      />
    </div>
  );
}
