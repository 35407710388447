import { useState, useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import axios from "axios";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { BASE_URL, Roles } from "../../../constants";
import SlidingPane from "react-sliding-pane";

function UserSlider({
  open,
  setOpen,
  selectedMaster,
  setSelectedMaster,
  onUpdate,
  onCreate,
}) {
  const user = selectedMaster;
  const [loading, setLoading] = useState(false);
  const [customers, setcustomers] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const loadData = async (searchText = "~ALL") => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Customers?searchText=${searchText}`
    );
    setcustomers(
      res.data.map((d) => ({ customerId: d.id, customerName: d.name }))
    );
    //setFilteredCustomer(res.d)
    res = await axios.get(
      `${BASE_URL}/Lookup/Warehouses?searchText=${searchText}&take=10&countryCode=null`
    );
    setWarehouse(res.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const submitForm = (values, resetForm) => {
    setLoading(true);
    if (selectedMaster.id) {
      axios
        .put(`${BASE_URL}/UserAccount`, values)
        .then((res) => {
          setLoading(false);
          toast.success("User Details Updated Successfully");
          onUpdate(values);
          //setOpen(false);
        })
        .catch((error) => {
          toast.error("An error occured while saving the user!");
          setLoading(false);
        });
    } else {
      axios
        .post(`${BASE_URL}/UserAccount`, values)
        .then((res) => {
          setLoading(false);
          toast.success("User Created Successfully");
          onCreate(values, res.data);
          setSelectedMaster({ ...values, id: res.data });
          //setOpen(false);
          //resetForm({ values: "" });
        })
        .catch((error) => {
          toast.error("An error occured while saving the user!");
          setLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: { ...selectedMaster },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (formik.values.role === 400) {
        if (formik.values.customers.length === 0) {
          toast.error("Please select atleast one customer!");
        } else {
          submitForm(values, resetForm);
        }
      } else {
        submitForm(values, resetForm);
      }
    },
  });

  return (
    <div>
      <SlidingPane
        closeIcon={
          <button
            type="button"
            className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        }
        hideHeader
        isOpen={open}
        from="right"
        width="680px"
        className=""
      >
        <div className="relative inset-0 overflow-hidden">
          <div className="w-screen max-w-2xl bg-white h-[88vh] overflow-y-scroll">
            <div className="px-4 py-4 bg-[#e9f4ff] sm:px-6 sticky top-0">
              <div className="flex items-start justify-between space-x-3">
                <div className="space-y-1 text-2xl">
                  {selectedMaster.id ? "Edit User" : "New User"}
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            {/* <div>
              <h1 className="flex text-lg font-medium text-gray-900 sm:mt-px sm:pt-2 sm:ml-6">
                General
              </h1>
            </div> */}
            <form className="flex flex-col" onSubmit={formik.handleSubmit}>
              <div className="flex-1 ">
                <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                  <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                    <label
                      htmlFor="username"
                      className="flex text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Username
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.values.id ? (
                        <label className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          {formik.values.username}
                        </label>
                      ) : (
                        <input
                          type="text"
                          name="username"
                          required
                          className="block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={formik.handleChange}
                          value={formik.values.username}
                          autoComplete="nope"
                        ></input>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="password"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Password
                        <span className="block text-red-500 mt-0.5 pl-1">
                          *
                        </span>
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      {formik.values.id ? (
                        <label className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          **********
                        </label>
                      ) : (
                        <input
                          type="password"
                          name="password"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                      )}
                    </div>
                  </div>
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="displayname"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Full Name
                        <span className="block text-red-500 mt-0.5 pl-1">
                          *
                        </span>
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="displayname"
                        id="displayname"
                        required
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                        onChange={formik.handleChange}
                        value={formik.values.displayname}
                      />
                    </div>
                  </div>

                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="jobTitle"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Job Title
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="jobTitle"
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md uppercase"
                        onChange={formik.handleChange}
                        value={formik.values.jobTitle}
                      />
                    </div>
                  </div>
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="email"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Email
                        <span className="block text-red-500 mt-0.5 pl-1">
                          *
                        </span>
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                    </div>
                  </div>
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="role"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Role
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <select
                        name="role"
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        onChange={(e) => {
                          formik.setFieldValue(
                            "role",
                            parseInt(e.target.value)
                          );
                        }}
                        value={formik.values.role}
                        disabled={
                          formik.values.role ===
                          Roles.find((r) => r.id === 500).id
                        }
                      >
                        {Roles.map((c) =>
                          c.id === 500 ? (
                            <option
                              key={c.id}
                              value={c.id}
                              label={c.name}
                              disabled
                            />
                          ) : (
                            <option key={c.id} value={c.id} label={c.name} />
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                {formik.values.role !== 300 && (
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="customer"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Customer
                      </label>
                    </div>
                    <div className="mt-1 col-span-2 w-full" id="compo">
                      <MultiSelect
                        data={customers}
                        name="customers"
                        dataItemKey="customerId"
                        textField="customerName"
                        className="block w-full h-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        onChange={(e) => {
                          formik.handleChange(e);
                          setOpen(true);
                        }}
                        value={formik.values?.customers}
                        clearButton={false}
                      />
                    </div>
                  </div>
                )}

                {formik.values.role === 300 && (
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="warehouse"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Warehouse
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <select
                        type="text"
                        name="warehouseId"
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        onChange={formik.handleChange}
                        value={formik.values.warehouseId}
                      >
                        {warehouse.map((c) => {
                          return (
                            <option key={c.id} value={c.id} label={c.name} />
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

                <fieldset>
                  <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                    <div>
                      <legend className="text-sm font-medium text-gray-900">
                        Actives
                      </legend>
                    </div>
                    <div className="space-y-5 sm:col-span-2">
                      <div className="space-y-5 sm:mt-0">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              name="active"
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              onChange={formik.handleChange}
                              checked={formik.values.active}
                              defaultChecked={formik.values.active}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6 fixed w-full bg-white bottom-0">
                  <div className="space-x-3 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                      disabled={
                        !(formik.isValid && formik.dirty) ||
                        formik.values.username === "" ||
                        formik.values.password === ""
                      }
                    >
                      {loading && (
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {selectedMaster.id ? "Update" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
          </div>
        </div>
      </SlidingPane>
    </div>
  );
}

export default UserSlider;
