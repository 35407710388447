import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { BASE_URL } from "../../../constants";
import SlidingPane from "react-sliding-pane";
import { filterBy } from "@progress/kendo-data-query";

const CitySlider = ({ open, setOpen, city, setCity, onUpdate, onCreate }) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);

  const onChange = (e) => {
    setCity({ ...city, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/Lookup/Countries?searchText=~ALL`).then((res) => {
      setCountries(res.data);
      setFilteredCountries(res.data);
    });
  }, []);

  const filterData = (filter) => {
    const data = countries.slice();
    return filterBy(data, filter);
  };

  const CountryFilterChange = (e) => {
    setFilteredCountries(filterData(e.filter));
  };
  const onCountryChange = (e) => {
    setCity({
      ...city,
      country: e.value,
      countryCode: e.value.code,
      countryName: e.value.name,
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    if (city.id) {
      const res = await axios.put(`${BASE_URL}/City`, city);
      if (res.status === 200) {
        toast.success("City Details updated Successfully!");
        onUpdate(city);
        setLoading(false);
        //setOpen(false);
      } else {
        toast.error("An error occured while updating City!");
        setLoading(false);
      }
    } else {
      try {
        const res = await axios.post(`${BASE_URL}/City`, city);
        toast.success(
          "City Added Successfully!"
          //,{onClose: () => setTimeout(() => setOpen(false), 5000),}
        );
        onCreate(city, res.data);
        const data = { ...city, id: res.data };
        setCity(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response.status === 400) {
          toast.error(e.response.data);
        } else {
          toast.error("An error occured while creating City!");
        }
      }
    }
  };

  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={open}
      from="right"
      width="680px"
      className=""
    >
      <div className="relative inset-0 overflow-hidden">
        <div className="w-screen max-w-2xl bg-white shadow-xl h-[75vh] overflow-y-scroll">
          <div className="px-4 py-4 bg-indigo-50 sm:px-6 sticky top-0">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1 text-2xl">
                {city.id ? "Edit City" : "New City"}
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <form className="h-full flex flex-col">
            <div className="flex-1">
              <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Code
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="code"
                      id="code"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={onChange}
                      value={city.code}
                    />
                  </div>
                </div>
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Name
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={onChange}
                      value={city.name}
                    />
                  </div>
                </div>
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 w-full">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Country
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="mt-1 w-full col-span-2" id="compo">
                    <ComboBox
                      data={filteredCountries}
                      textField="name"
                      dataItemKey="code"
                      id="countrycode"
                      name="name"
                      suggest={true}
                      clearButton={true}
                      filterable={true}
                      onFilterChange={CountryFilterChange}
                      className=" block focus:ring-blue-500 focus:border-blue-500 h-8 w-full shadow-sm sm:text-sm border-gray-300 text-black rounded-md"
                      onChange={onCountryChange}
                      value={city.country}
                    />
                  </div>
                </div>

                <div className="fixed bottom-0 w-full px-4 border-t border-gray-200 py-4 sm:px-6">
                  <div className="space-x-3 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={onSubmit}
                      disabled={
                        city.name === "" ||
                        city.code === "" ||
                        city.country === ""
                      }
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    >
                      {loading && (
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {city.id ? "Update" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ToastContainer />
        </div>
      </div>
    </SlidingPane>
  );
};

export default CitySlider;
