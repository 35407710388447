import { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import JobCardList from "../job/JobCardList";
import JobCard from "../job/JobCard";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../../index.scss";

export default function PoActionsSlider({ show, setOpen, selectedPos }) {
  const [jobType, setJobType] = useState(null);
  const [isShowing, setIsShowing] = useState(true);

  useEffect(() => {
    if (jobType === "0" || jobType === null) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
  }, [jobType]);

  const onProceedClick = (e, id) => {
    // const id = e.target.id;
    setJobType(id);
    setIsShowing(false);
  };

  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={show}
      from="right"
      width="384px"
      className=""
    >
      <div className="bg-white overflow-hidden fixed w-96">
        {isShowing ? (
          <div>
            <div className="text-white py-6 px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="text-lg font-medium text-black">PO Actions</div>
                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="rounded-md text-black focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <JobCardList
              setOpen={setOpen}
              setIsShowing={setIsShowing}
              setJobType={setJobType}
              jobType={jobType}
              onProceedClick={onProceedClick}
            />
          </div>
        ) : (
          <div>
            <div className="text-white py-6 px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="text-lg font-medium text-black">PO Actions</div>
                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="rounded-md text-black focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => setJobType("0")}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <JobCard
              jobType={jobType}
              selectedState={selectedPos}
              setOpen={setOpen}
            />
          </div>
        )}
      </div>
    </SlidingPane>
  );
}
