import React, { useContext, useEffect, useState } from "react";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { DropDownCell } from "./DropDownCell";
import { CommandCell } from "./CommandCell";
import { TimeCell } from "./TimeCell";
import { ActiveCell } from "./ActiveCell";
import EditableGrid from "./EditableGrid";
import AppContext from "../../context/app-context";
import { toast } from "react-toastify";
import { formatDate } from "@telerik/kendo-intl";

const MyCommandCell = (props) => {
  const { enterEdit, remove, add, discard, update, cancel, editField } = props;
  return (
    <CommandCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );
};

const ScheduleForm = ({ closeModel, customers }) => {
  const { v4: uuidv4 } = require("uuid");
  const authCtx = useContext(AppContext);
  const defaultCustomerForm = {
    id: uuidv4(),
    customerId: null,
    time: null,
    active: true,
    inEdit: true,
  };
  const [customerForm, setCustomerForm] = useState(defaultCustomerForm);
  const [popupCustomers, setCustomers] = useState(customers);
  const [scheduleData, setScheduleData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const formatTime = (dateString, returnType) => {
    if (!(dateString instanceof Date) || isNaN(dateString)) {
      console.error("Invalid date provided:", dateString);
      return null; // or some default value
    }
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const formattedhours = hours % 12 || 12;
    if (returnType === "display")
      return `${String(formattedhours).padStart(2, "0")}:${minutes} ${ampm}`;
    else if (returnType === "toApi")
      return `${String(hours).padStart(2, "0")}:${minutes}:00`;
    else
      return {
        hour: hours,
        minute: minutes,
      };
  };

  const onAddData = () => {
    const newData = {
      id: uuidv4(),
      isNew: true,
      ...defaultCustomerForm,
    };
    setCustomerForm(newData);
    setScheduleData((pre) => [
      ...pre.map((x) => ({ ...x, inEdit: false })),
      newData,
    ]);
  };

  function isString(value) {
    return typeof value === "string" || value instanceof String;
  }

  const onSave = async () => {
    setButtonLoading(true);
    const schedules = scheduleData.map((item) => ({
      id: isString(item.id) ? 0 : item.id,
      isActive: item.active,
      customerId: item.customerId,
      time: item.time,
      createdUserId: item?.createdUserId || authCtx.profile.id,
      creationDate: item?.creationDate || new Date(),
    }));
    const res = await axios.post(`${BASE_URL}/CustomerScheduler`, schedules);
    if (res.status === 200) {
      toast.success("schedules updated successfully!");
    } else {
      toast.error("An error occured while updating schedules!");
    }
    setButtonLoading(false);
  };

  const loadData = async () => {
    setGridLoading(true);
    const res = await axios.get(`${BASE_URL}/CustomerScheduler`);
    if (res.status === 200) {
      if (res.data.length > 0) {
        setScheduleData(
          res.data.map((x) => ({
            ...x,
            active: x.isActive,
            time: new Date(x.time),
            customerName: customers.find((y) => y.id === x.customerId).name,
            displayTime: formatDate(new Date(x.time), "t"),
            lastRefreshed:
              x.lastRefreshed !== null
                ? formatDate(new Date(x.lastRefreshed), "yyyy-MM-dd") +
                  " " +
                  formatDate(new Date(x.lastRefreshed), "t")
                : null,
          }))
        );
        const existingCustomerIds = res.data.map((x) => x.customerId);
        updateCustomersDropdown(existingCustomerIds);
      } else setScheduleData([defaultCustomerForm]);
    } else {
      toast.error("An error occured while getting schedules!");
    }
    setGridLoading(false);
  };

  const updateCustomersDropdown = (customerIds) => {
    setCustomers(customers.filter((x) => !customerIds.includes(x.id)));
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="container border mx-auto bg-zinc-100 rounded p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Scheduler</h2>
        <button onClick={closeModel} className=" p-2 rounded text-white">
          <img src="closeIcon.svg" className="h-4 text-white" />
        </button>
      </div>

      <div className="flex mb-4 gap-2 justify-end">
        <button
          type="button"
          className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-sky-600 shadow-sm text-sm font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-sky-400 disabled:cursor-not-allowed disabled:border-sky-400 disabled:shadow-none"
          onClick={() => onAddData()}
          disabled={buttonLoading}
        >
          + Add
        </button>
        <button
          type="button"
          className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-green-600 shadow-sm text-sm font-semibold rounded-md bg-green-600 text-white focus:outline-none disabled:bg-green-400 disabled:cursor-not-allowed disabled:border-green-400 disabled:shadow-none"
          onClick={() => onSave()}
          disabled={buttonLoading}
        >
          {buttonLoading && (
            <svg
              aria-hidden="true"
              role="status"
              class="mr-2 inline h-5 w-5 animate-spin text-green-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#FFF"
              ></path>
            </svg>
          )}
          Save
        </button>
      </div>

      <div className="overflow-hidden relative rounded-lg shadow-[0_0_4px_rgba(0,0,0,0.1)]">
        <EditableGrid
          data={scheduleData}
          setData={setScheduleData}
          customers={popupCustomers}
          formatTime={formatTime}
          setEditingForm={setCustomerForm}
          editingForm={customerForm}
          loading={gridLoading}
          updateCustomersDropdown={updateCustomersDropdown}
        />
      </div>
    </div>
  );
};

export default ScheduleForm;
