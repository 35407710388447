import { useState, useEffect, useContext } from "react";
import axios from "axios";
import BaseMasterGrid from "../masters/BaseMasterGrid"; // Data grid component
import KendoLoader from "../KendoLoader"; // Loader component
import { BASE_URL } from "../../constants"; // Base URL for API calls
import AppContext from "../../context/app-context"; // Context for app-wide state
import { useNavigate } from "react-router-dom";

export default function PlannerMaster() {
  const defaultEntity = {
    plannerId: "", 
    plannerName: "",
    description: "",
    plannerDate: "",
    status: "Active",
  };

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(defaultEntity);
  const { searchText } = useContext(AppContext);
  const [pageState, setPageState] = useState({ take: 10, skip: 0, total: 0 });

  const gridColumns = [
    { field: "plannerId", title: "Planner ID", filterable: true, sortable: true,width: "200" },
    { field: "plannerName", title: "Planner Name", filterable: true, sortable: true },
    { field: "description", title: "Description" },
    { field: "plannerDate", title: "Date" },
    { field: "status", title: "Status" },
  ];

  const navigate = useNavigate();

  const onEditClick = (e) => {
    const dataItem = e.dataItem;
    setSelectedMaster({ ...dataItem });
    setOpen(true);
  };

  const onCreateClick = () => {
    navigate("new");
  };



  const onGridFilter = (e) => {
    const filterText = e.filter && e.filter.filters[0].value ? e.filter.filters[0].value : "~ALL";
    const newState = { ...pageState, filter: { ...e.filter }, skip: 0, value: filterText };
    setPageState(newState);
    
  };

 

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8">
      <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Planner Details</h3>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onCreateClick}
          >
            Add Planner
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow relative overflow-hidden sm:rounded-lg">
              {loading && <KendoLoader />}
              <BaseMasterGrid
                data={entities}
                columns={gridColumns}
                onEditClick={onEditClick}
                setPageState={setPageState}
               
                pageState={pageState}
                onGridFilter={onGridFilter}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <PlannerSlider
        open={open}
        setOpen={setOpen}
        selectedMaster={selectedMaster}
        setSelectedMaster={setSelectedMaster}
        onUpdate={onUpdate}
        onCreate={onCreate}
      /> */}
    </div>
  );
}
