import * as React from "react";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePicker } from "@progress/kendo-react-dateinputs";
export const ActiveCell = (props) => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.checked,
      });
    }
  };
  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <div className=" h-9 flex justify-center items-center">
          <input
            name="active"
            type="checkbox"
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            onChange={handleChange}
            checked={dataValue}
          />
        </div>
      ) : (
          <div
            className={`${
              dataValue
                ? "bg-sky-500 border-sky-600"
                : "bg-red-500 border-red-600"
            } w-16 rounded-xl text-white text-xxs font-semibold h-5 flex justify-center items-center`}
          >
            {dataValue ? "Active" : "InActive"}
          </div>
      )}
    </td>
  );
};
